import { HeaderFooterProvider } from 'application/app/HeaderFooter'
import PrivateRoute from 'application/app/PrivateRoute'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { useAppSelector } from 'application/domain/store/hooks'

import s from '../AppView.module.scss'
import { PageLayoutProvider } from '../common/PageLayout/PageLayoutContext'
import ScanCarPage from '../pages/bookingPages/ScanCarPage/ScanCarPage'

import { LazyBookingOrderPage, LazyKassaPanelPage, LazyOrderPanelPage } from './LazyRoutes'

const ExtraSessionNavigation = () => {
  const appMode = useAppSelector(({ userReducer }) => userReducer.appMode)
  return (
    <HeaderFooterProvider>
      <div className={s.page_content}>
        <PageLayoutProvider>
          <Routes>
            <Route element={<Navigate to="/barista_panel" replace />} path="/" />
            <Route
              element={
                <PrivateRoute>
                  <LazyOrderPanelPage appMode={appMode} />
                </PrivateRoute>
              }
              path="/barista_panel"
            />
            <Route
              element={
                <PrivateRoute>
                  <LazyBookingOrderPage appMode={appMode} />
                </PrivateRoute>
              }
              path="/barista_panel/:order_id"
            />
            <Route
              path="/scan_car"
              element={
                <PrivateRoute>
                  <ScanCarPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/kassa_panel"
              element={
                <PrivateRoute>
                  <LazyKassaPanelPage appMode={appMode} />
                </PrivateRoute>
              }
            />
          </Routes>
        </PageLayoutProvider>
      </div>
    </HeaderFooterProvider>
  )
}

export default React.memo(ExtraSessionNavigation)
