import React from 'react'
import { ReactComponent as ScopeSvg } from 'images/icons/scope.svg'

import s from './SearchInput.module.scss'

type Props = {
  value: string
  onChange: Function
  placeholder?: string
}

const SearchInput = ({ value, onChange, placeholder }: Props) => {
  return (
    <div className={s.container}>
      <div className={s.icon}>
        <ScopeSvg />
      </div>
      <input
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  )
}

export default React.memo(SearchInput)
