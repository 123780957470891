/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { OrderType } from 'types/orderType'
import _ from 'lodash'
import { ChangeOrderStatusMutation } from 'application/domain/useCase/order/orderItemMutation'

import MWContainer from '../MWContainer'
import { useTheme } from '../../Hooks/useTheme'
import { Button } from '../../uiComponents'

import OrderItem from './OrderItem'
import s from './OrderMW.module.scss'

type NotAcceptedOrderType = {
  cost: number
  count: number
  id: number
  order: OrderType
  order_items: Array<OrderType>
}

type Props = {
  data: Array<NotAcceptedOrderType>
}

const OrderMW = ({ data }: Props) => {
  const navigate = useNavigate()
  const { hash, search } = useLocation()
  const changeStatusMutation = ChangeOrderStatusMutation()
  const opened = useMemo(() => {
    return hash.indexOf('order') !== -1
  }, [hash])

  const { isLightTheme } = useTheme()

  const order_id = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const id = searchParams.get('order_id')
    return parseInt(id ? id : '0', 10)
  }, [search])

  const order = useMemo(() => {
    if (data && order_id) {
      return _.find(data, { id: order_id })
    }
    return null
  }, [data, order_id])

  const acceptOrder = useCallback(() => {
    if (order && order.order) {
      changeStatusMutation.mutate({ open_order: order.order })
      navigate(-1)
    }
  }, [order, changeStatusMutation, navigate])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (hash.indexOf('order') !== -1) {
          navigate(-1)
        }
      }}
      className={isLightTheme ? s.light : ''}
      btnClassName={isLightTheme ? s.light_btn : ''}
    >
      <div className={s.inner_container}>
        {order && order.order_items ? (
          order.order_items.map((item, i: number) => (
            <OrderItem data={item} i={i} key={`order_${item.id}`} />
          ))
        ) : (
          <></>
        )}
      </div>

      <Button
        text="Принять"
        color="green"
        onClick={() => acceptOrder()}
        containerClassName={s.btn_container}
      />
    </MWContainer>
  )
}

export default React.memo(OrderMW)
