import { useQuery } from 'react-query'
import {
  activateBonusCardRequest,
  changeClientBlockStatusRequest,
  checkBonusCardRequest,
  clientNameRequest,
  fetchClient,
  fetchClientOrders,
  historyClientsRequest,
  historyClientsRequestById,
} from 'application/data/api/client'
import { CallbackType } from 'application/domain/types/callback'
import { BonusCardType, ClientType } from 'types/UserType'
import { Order } from 'application/domain/entity/order/Order'

export const checkBonusCard = async ({
  onSuccess,
  onError,
  phone,
  code,
}: {
  phone: string
  code: string
} & CallbackType) => {
  try {
    const data = await checkBonusCardRequest(phone, code)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const activateBonusCard = async ({
  onSuccess,
  onError,
  client,
  card,
}: {
  client?: ClientType
  card?: BonusCardType
} & CallbackType) => {
  try {
    const data = await activateBonusCardRequest(client, card)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const editClientName = async ({
  onSuccess,
  onError,
  id,
  name,
}: {
  id: number
  name: string
} & CallbackType) => {
  try {
    const data = await clientNameRequest(id, name)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const getClient = async ({
  onSuccess,
  onError,
  phone,
}: {
  phone: string
} & CallbackType) => {
  try {
    const data = await fetchClient(phone)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const historyClients = async ({
  onSuccess,
  onError,
  phone,
}: {
  phone: string
} & CallbackType) => {
  try {
    const data = await historyClientsRequest(phone)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const historyClientsById = async ({
  onSuccess,
  onError,
  id,
}: {
  id: string
} & CallbackType) => {
  try {
    const data = await historyClientsRequestById(id)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const useClientOrdersHistory = (client_id: number, outlet_id: number) => {
  const query = useQuery<{ last_five_finish_orders: Array<Order> }>(
    ['client_orders_history', client_id],
    () => fetchClientOrders(client_id, outlet_id),
    {
      enabled: client_id && outlet_id ? true : false,
    },
  )
  return {
    ...query,
    data: query.data ? query.data.last_five_finish_orders : undefined,
    isLoading: client_id && outlet_id ? query.isLoading : false,
  }
}

export const changeClientBlockStatus = changeClientBlockStatusRequest
