import { IsMobileContext } from 'application/app/IsMobile'
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Loader from '../../Loader'
import MWContainer from '../MWContainer'

import s from './IframeMW.module.scss'

const IframeMW = () => {
  const iframeRef = useRef<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const isMobile = useContext(IsMobileContext)
  const { hash } = useLocation()

  const navigate = useNavigate()
  const opened = useMemo(() => {
    return hash.indexOf('iframe') !== -1
  }, [hash])

  const closeClickHandler = useCallback(() => {
    navigate('/', { replace: true })
  }, [navigate])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (hash.indexOf('iframe') !== -1) {
          closeClickHandler()
        }
      }}
      onClose={closeClickHandler}
      className={s.modal}
      btnClassName={s.light_btn}
    >
      {opened ? (
        <div className={s.inner_container}>
          {isLoading ? (
            <div className={s.loader}>
              <Loader />
            </div>
          ) : (
            <></>
          )}
          <iframe
            src="https://docs.google.com/document/d/e/2PACX-1vS4XSF8L26p1jTnASOe2elQlgw-346f6mQUsKDGipJmpR0i_zCoBv75OmlMq7vu81WLsMW05pN4nofT/pub?embedded=true"
            onLoad={() => setIsLoading(false)}
            id="notionFrame"
            ref={iframeRef}
            style={{
              width: '100%',
              height: isMobile ? '89vh' : '80vh',
              border: 0,
              padding: 0,
              visibility: isLoading ? 'hidden' : 'visible',
            }}
          ></iframe>
        </div>
      ) : (
        <></>
      )}
    </MWContainer>
  )
}

export default React.memo(IframeMW)
