import { BonusCardType, ClientType } from 'types/UserType'

import apiClient from '../apiClient'

export const fetchClient = async (phone: string) => {
  const { data } = await apiClient.post('/api/client/set', {
    phone: phone,
  })

  return data
}

export const clientNameRequest = async (id: number, name: string) => {
  const { data } = await apiClient.post('/api/client/setClientName', {
    client_id: id,
    name: name,
  })

  return data
}

export const checkBonusCardRequest = async (phone: string, code: string) => {
  const { data } = await apiClient.post('/api/bonus_card/check', {
    phone: phone,
    code: code,
  })
  return data
}

export const activateBonusCardRequest = async (client?: ClientType, card?: BonusCardType) => {
  const { data } = await apiClient.post('/api/bonus_card/activate', {
    client: client,
    card: card,
  })
  return data
}

export const historyClientsRequest = async (phone: string) => {
  const { data } = await apiClient.post('/api/admin/findClient?panel=true', { phone })
  return data
}

export const historyClientsRequestById = async (id: string) => {
  const { data } = await apiClient.post('/api/admin/findClient?panel=true', { id })
  return data
}

export const fetchClientOrders = async (client_id: number, outlet_id: number) => {
  const { data } = await apiClient.get(
    `/api/barista/clients/orders?client_id=${client_id}&outlet_id=${outlet_id}`,
  )
  return data
}

export const changeClientBlockStatusRequest = async (client_id: number, blocked: boolean) => {
  const { data } = await apiClient.post('/api/barista/client/block', {
    client_id,
    blocked,
  })
  return data
}
