import {
  loginRequest,
  passResetEmailRequest,
  registrationRequest,
  saveNewPasswordRequest,
} from 'application/data/api/user'

export const login = loginRequest

export const sendEmailPasswordReset = passResetEmailRequest

export const registration = registrationRequest

export const saveNewPassword = saveNewPasswordRequest
