export const alohaIds = [40, 34]

export const pusherApiKey =
  window.ENV && window.ENV.pusherApiKey ? window.ENV.pusherApiKey : '20cc574e0fb2d8b4043f'

export const defaultCarTypes = [
  {
    id: 1,
    name: 'Легковые',
    classes: ['A', 'B', 'C', 'D', 'F', 'S'],
  },
  {
    id: 2,
    name: 'Кроссоверы',
    classes: ['X'],
  },
  {
    id: 3,
    name: 'Внедорожники',
    classes: ['J'],
  },
  {
    id: 4,
    name: 'Минивэны',
    classes: ['M'],
  },
]

export const disconts = [
  {
    id: 1,
    value: 0,
    name: '0%',
  },
  {
    id: 2,
    value: 5,
    name: '5%',
  },
  {
    id: 3,
    value: 10,
    name: '10%',
  },
  {
    id: 4,
    value: 15,
    name: '15%',
  },
  {
    id: 5,
    value: 20,
    name: '20%',
  },
  {
    id: 6,
    value: 30,
    name: '30%',
  },
  {
    id: 7,
    value: 50,
    name: '50%',
  },
  // {
  //   id: 7,
  //   value: 99,
  //   name: 'Переделан',
  // },
]
