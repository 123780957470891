import React, { useMemo, useState, useEffect, useCallback, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { IsMobileContext } from 'application/app/IsMobile'

import MWContainer from '../MWContainer'
import SelectCarForm from '../../SelectCarForm/SelectCarForm'

import s from './AddOrEditCarMW.module.scss'
import AddCarPage from './AddCarPage/AddCarPage'

type Props = {
  clientId: number
  onAddCar: Function
}

const AddOrEditCarMW = ({ clientId, onAddCar }: Props) => {
  const location = useLocation()
  const navigation = useNavigate()
  const isMobile = useContext(IsMobileContext)
  const opened = useMemo(() => {
    return location.hash.indexOf('add_car') !== -1
  }, [location])

  const onAddCarHandler = useCallback(
    (id: number) => {
      onAddCar(id)
      // navigation(-1)
    },
    [onAddCar],
  )

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (location.hash.indexOf('add_car') !== -1) {
          navigation(-1)
        }
      }}
      color="white"
      className={`${s.modal} ${s.modal_mobile}`}
    >
      <div className={s.container}>
        {isMobile ? (
          <AddCarPage clientId={clientId} onAddCar={onAddCarHandler} />
        ) : (
          <>
            <div className={s.title}>Новая машина</div>
            <SelectCarForm onSuccess={onAddCarHandler} clientId={clientId} />
          </>
        )}
      </div>
    </MWContainer>
  )
}

export default React.memo(AddOrEditCarMW)
