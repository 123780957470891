import React, { useCallback, useEffect, useState } from 'react'
import { Product } from 'application/domain/entity/product/Product'
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import {
  UpdateProductOptionStatusMutation,
  UpdateProductStatusMutation,
} from 'application/domain/useCase/products/productsMutations'
import { useAppSelector } from 'application/domain/store/hooks'
import { ProductVariety } from 'application/domain/entity/product/ProductVariety'
import { createDonSessionLog } from 'application/domain/useCase/barista/barista'
import ToastNotify from 'application/app/ToastNotify/ToastNotify'
import { useDispatch } from 'react-redux'
import { setNeedCheckProducts } from 'application/domain/store/reducers/userSlice'

import MWContainer from '../MWContainer'
import { Button } from '../../uiComponents'

import s from './CheckProductsAvailabilityMW.module.scss'

type Props = {
  opened: boolean
  setOpened: Function
  products: Array<Product>
}

const CheckProductsAvailabilityMW = ({ opened, setOpened, products }: Props) => {
  const dispatch = useDispatch()
  const { outlet, user } = useAppSelector(({ userReducer }) => ({
    outlet: userReducer.outlet,
    user: userReducer.user,
  }))
  const closeMW = useCallback(() => setOpened(false), [setOpened])

  const [data, setData] = useState<Array<Product>>(products)
  const [checked, setChecked] = useState<boolean>(false)
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)

  const updateProductStatusMutation = UpdateProductStatusMutation()
  const updateVarietyStatusmutation = UpdateProductOptionStatusMutation()

  const updateProductStatus = useCallback(
    (product: Product, status: string) =>
      updateProductStatusMutation.mutate({
        outlet_id: outlet?.id || 0,
        client_product_id: product.id,
        product_id: product?.id,
        status,
      }),
    [updateProductStatusMutation, outlet],
  )

  const updateVarietyStatus = useCallback(
    (product: Product, id: number, status: string) => {
      //@ts-ignore
      if (
        product?.client_category_name.toLocaleLowerCase() === 'пончики' ||
        product?.client_category_name.toLocaleLowerCase() === 'еда' ||
        product?.is_combo
      ) {
        updateVarietyStatusmutation.mutate({
          product_id: product.id,
          outlet_id: outlet?.id || 0,
          option_id: id,
          status: status,
        })
      } else {
        updateVarietyStatusmutation.mutate({
          product_id: 0,
          outlet_id: outlet?.id || 0,
          option_id: id,
          status: status,
        })
      }
    },

    [updateVarietyStatusmutation, outlet],
  )

  const onProductAvailabilityChange = useCallback(
    (index: number, newStatus: string) => {
      let temp = _cloneDeep(data)
      temp = _set(temp, `[${index}].availability_status`, newStatus)
      setData(temp)
      setChecked(true)

      //@ts-ignore
      const prod: Product = _get(temp, `[${index}]`)
      updateProductStatus(prod, newStatus)
    },
    [data, updateProductStatus],
  )

  const onVarietyAvailabilityChange = useCallback(
    (pIndex: number, vIndex: number, newStatus: string) => {
      let temp = _cloneDeep(data)
      temp = _set(temp, `[${pIndex}].varieties[${vIndex}].availability_status`, newStatus)
      setData(temp)
      setChecked(true)

      //@ts-ignore
      const prod: Product = _get(temp, `[${pIndex}]`)
      //@ts-ignore
      const variety: ProductVariety = _get(temp, `[${pIndex}].varieties[${vIndex}]`)
      updateVarietyStatus(prod, variety.id, newStatus)
    },
    [data, updateVarietyStatus],
  )

  const onCheckClickHandler = useCallback(() => {
    const prod = _filter(data, (item) => {
      if (item.availability_status !== 'in_stock') {
        return true
      }
      let somethingNotInStock = false
      _map(item.varieties, (v) => {
        if (v.availability_status !== 'in_stock') {
          somethingNotInStock = true
        }
      })
      return somethingNotInStock
    })
    const fullFiltered = _map(prod, (item) => ({
      ...item,
      varieties: _filter(item.varieties, (v) => v.availability_status !== 'in_stock'),
    }))
    const prodResults = _map(
      _filter(fullFiltered, (item) => item.client_category_name === 'Классика'),
      (item) => ({
        name: item.product_name,
        status: item.availability_status,
        varieties: _map(item.varieties, (v) => ({
          name: v.name,
          status: v.availability_status,
        })),
      }),
    )
    setSaveIsLoading(true)
    const sendData = {
      key: 'start_session_products_check',
      status: checked ? 'checked' : 'not_checked',
      don_session_id: user?.session.id || 0,
      value: JSON.stringify(prodResults),
    }
    createDonSessionLog(sendData)
      .then(() => {
        ToastNotify('Результаты сохранены')
        setNeedCheckProducts(false)(dispatch)
        setSaveIsLoading(false)
        closeMW()
      })
      .catch(() => {
        ToastNotify('Произошла ошибка')
        setSaveIsLoading(false)
      })
  }, [data, checked, user, closeMW, dispatch])

  useEffect(() => {
    if (opened) {
      setData(products)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (opened) {
          closeMW()
        }
      }}
      onClose={closeMW}
      color="white"
      className={s.modal_container}
    >
      <div className={s.title}>Список найденых продуктов:</div>
      {data.map((item, pIndex) => {
        return (
          <div className={s.product_container}>
            <div className={s.product}>
              <div className={s.name}>{item.product_name}</div>
              <div className={s.buttons}>
                <Button
                  text="В наличии"
                  color={item.availability_status === 'in_stock' ? 'green' : 'gray'}
                  onClick={() => onProductAvailabilityChange(pIndex, 'in_stock')}
                />
                <Button
                  text="Закончился"
                  color={item.availability_status === 'ended' ? 'orange' : 'gray'}
                  onClick={() => onProductAvailabilityChange(pIndex, 'ended')}
                />
                <Button
                  text="Не доступен"
                  color={item.availability_status === 'not_available' ? 'red' : 'gray'}
                  onClick={() => onProductAvailabilityChange(pIndex, 'not_available')}
                />
              </div>
            </div>
            <div className={s.varieties}>
              {item.varieties.map((variety, index) => {
                return (
                  <div className={s.variety}>
                    <div className={s.name}>
                      {index + 1}. {variety.name}
                    </div>
                    <div className={s.buttons}>
                      <Button
                        text="В наличии"
                        color={variety.availability_status === 'in_stock' ? 'green' : 'gray'}
                        onClick={() => onVarietyAvailabilityChange(pIndex, index, 'in_stock')}
                      />
                      <Button
                        text="Закончился"
                        color={variety.availability_status === 'ended' ? 'orange' : 'gray'}
                        onClick={() => onVarietyAvailabilityChange(pIndex, index, 'ended')}
                      />
                      <Button
                        text="Не доступен"
                        color={variety.availability_status === 'not_available' ? 'red' : 'gray'}
                        onClick={() => onVarietyAvailabilityChange(pIndex, index, 'not_available')}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
      <Button
        color="orange"
        text="Я проверил"
        onClick={onCheckClickHandler}
        containerClassName={s.check_btn}
        isLoading={saveIsLoading}
      />
    </MWContainer>
  )
}

export default React.memo(CheckProductsAvailabilityMW)
