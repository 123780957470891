import React from 'react'
import cn from 'classnames'
import { UniversalInputComponent } from 'application/presentation/common/InputComponents'

import s from '../Filters.module.scss'
import { FilterItemType } from '../Filters'
import Button from '../../Button'

type Props = {
  data: FilterItemType | undefined
  onFilterItemChange: (value: any, index: number) => void
  onSubmit: Function
  withRemove?: boolean
  removeItem?: Function
}

const FilterItemContent = ({
  data,
  onFilterItemChange,
  onSubmit,
  withRemove,
  removeItem,
}: Props) => {
  return data ? (
    <div className={s.filter_item_content}>
      <div className={s.title}>{data.name}:</div>
      {data.type === 'input' || data.type === 'date_interval' ? (
        data.data.map((item, index: number) => {
          return (
            <UniversalInputComponent
              key={`filter_input_${data.id}_${index}`}
              title={item.name}
              value={item.value}
              onChange={(val: any) => onFilterItemChange(val, index)}
              type={item.type || 'text'}
            />
          )
        })
      ) : data.type === 'selector' ? (
        data.data.map((item, index) => {
          return (
            <div
              key={`filter_input_${data.id}_${index}`}
              className={cn(s.filter_variety, data.value === item.value ? s.selected : '')}
              onClick={() => onFilterItemChange(item.value, index)}
            >
              {item.name}
            </div>
          )
        })
      ) : (
        <></>
      )}
      <div className={s.buttons}>
        <Button color="green" text="Применить" onClick={onSubmit} className={s.submit_button} />
        {withRemove && (
          <>
            <span style={{ width: '2rem' }}></span>
            <Button color="red" text="Удалить" onClick={removeItem} className={s.submit_button} />
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default React.memo(FilterItemContent)
