import React from 'react'
import Modal from 'react-modal'
import { ReactComponent as CloseSvg } from 'images/icons/white_close_icon.svg'
import cn from 'classnames'

import s from './MWContainer.module.scss'

type Props = {
  isOpen: boolean
  closeTimeoutMS?: number
  style?: any
  onRequestClose?: Function
  onClose?: Function
  className?: string
  children: React.ReactNode
  btnClassName?: string
  color?: 'white'
}

const MWContainer = ({
  isOpen,
  closeTimeoutMS,
  style,
  onRequestClose,
  onClose,
  className,
  children,
  btnClassName,
  color,
}: Props) => {
  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          overflow: 'auto',
          padding: '3rem 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      closeTimeoutMS={500}
      isOpen={isOpen}
      className={cn(s.modal_window_desk, color ? s[color] : '', className || '')}
      onRequestClose={() => {
        if (onRequestClose) {
          onRequestClose()
        }
      }}
    >
      <div className={s.container}>
        <div
          className={`${s.modal_close_button} ${color === 'white' ? s.black_btn : ''} ${
            btnClassName ? btnClassName : ''
          }`}
          onClick={() => {
            if (onClose) {
              onClose()
            } else {
              history.go(-1)
            }
          }}
        >
          <CloseSvg />
        </div>
        {children}
      </div>
    </Modal>
  )
}

export default React.memo(MWContainer)
