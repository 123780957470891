import {
  addClientConfigsByClientIdRequest,
  deleteClientConfigByClientIdRequest,
} from 'application/data/api/client_configs'
import queryClient from 'application/data/apiClient/queryClient'
import { useMutation } from 'react-query'
import _filter from 'lodash/filter'

export const addClientConfig = addClientConfigsByClientIdRequest
export const deleteClientConfig = deleteClientConfigByClientIdRequest

type DeleteClientConfigMutationDataType = {
  client_id: number
  id: number
}

export const DeleteClientConfigMutation = (callback?: Function) => {
  const mutation = useMutation(
    ({ client_id, id }: DeleteClientConfigMutationDataType) =>
      deleteClientConfigByClientIdRequest(client_id, id),
    {
      onMutate: async ({ client_id, id }) => {
        await queryClient.cancelQueries('client_configs')
        const previousData = queryClient.getQueryData<any>(['client_configs', client_id])
        if (previousData) {
          let newData = _filter(previousData, (item) => item.id !== id)
          queryClient.setQueryData(['client_configs', client_id], newData)
          if (callback) {
            callback(newData)
          }
        }
        return { previousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(['client_configs', variables.client_id], context.previousData)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('client_configs')
      },
    },
  )
  return mutation
}
