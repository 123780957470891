/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */

import { AuthContextType } from 'application/app/AuthContext'
import apiClient from 'application/data/apiClient'
import { setOutlet, setToken } from 'application/domain/store/reducers/userSlice'
import { getOutlets } from 'application/domain/useCase/outlet/outlet'
import { getDomain } from 'application/utils/urlUtils'
import { useQuery } from 'react-query'
import { AuthUserType, OutletType } from 'types/UserType'
import _find from 'lodash/find'

export const InitUser = async (authContext: AuthContextType, dispatch: any) => {
  const urlParams = new URLSearchParams(window.location.search)
  const phone = urlParams.get('login') || localStorage.getItem('phone')
  const password = urlParams.get('password') || localStorage.getItem('password')
  const user_token = urlParams.get('user_token') || localStorage.getItem('user_token')
  const domain = getDomain(window.location.origin, true)

  if (user_token) {
    setToken(user_token)(dispatch)
    //@ts-ignore
    apiClient.defaults.headers.Authorization = `Bearer ${user_token}`
  }
  if (phone && password) {
    const user = await authContext.login({
      data: { phone, password },
      onStart: () => {},
      onSuccess: () => {},
      onError: () => {},
    })
    if (user && domain === 'isdon.ru') {
      window.location.href = `https://${user.user.owner_url}.${domain}/?user_token=${user.token}&login=${phone}&password=${password}`
    }
    if (user) {
      const outlets = await getOutlets()
      if (outlets && outlets.outlets.length) {
        const outletId = getDefaultOutlet(user.user)
        const outlet = _find(outlets.outlets, (item) => item.id === outletId)
        setOutlet(outlet)(dispatch)
      }
    }
    return user
  }
  return null
}

export const getUserRole = (user: AuthUserType): string => {
  let role = ''
  if (user) {
    if (user.roles && user.roles.length) {
      role = user.roles[0].name
    }
    if (user.role && user.role.role) {
      role = user.role.role
    }
  }
  return role
}

export const isAdmin = (user: AuthUserType): boolean =>
  getUserRole(user) === 'admin' ? true : false

export const isBarista = (user: AuthUserType): boolean =>
  getUserRole(user).indexOf('barista') !== -1 ? true : false

export const isConditer = (user: AuthUserType): boolean =>
  getUserRole(user) === 'conditer' ? true : false

export const isAdept = (user: AuthUserType): boolean =>
  getUserRole(user).indexOf('adept') !== -1 ? true : false

export const isSupplier = (user: AuthUserType): boolean =>
  getUserRole(user).indexOf('supplier') !== -1 ? true : false

export const isUserHaveRole = (user: AuthUserType): boolean =>
  getUserRole(user).length ? true : false

export const isUserHaveOnlyExtrasession = (user: AuthUserType): boolean =>
  user && (!user.session || !user.session.id) && user.extrasession && user.extrasession.id
    ? true
    : false

export const userIsActive = (user: AuthUserType): boolean =>
  user && user.session && user.session.state === 'active' ? true : false

export const userSessionIsClosed = (user: AuthUserType): boolean =>
  (user && user.session && user.session.state === 'finished') || user?.session === null
    ? true
    : false

export const userHaveAccessToPage = (
  user: AuthUserType,
  currentOutlet: OutletType | null,
  outlet_id: number,
): boolean => {
  return isAdmin(user) || (isBarista(user) && currentOutlet && currentOutlet.id === outlet_id)
    ? true
    : false
}

export const getDefaultOutlet = (user: AuthUserType) => {
  const session_outlet = user
    ? user.session && user.session.outlet_id
      ? user.session.outlet_id
      : user.extrasession && user.extrasession.outlet_id
      ? user.extrasession.outlet_id
      : 0
    : 0

  const local_outlet = localStorage.getItem('outlet_id')
  if (isBarista(user)) {
    return session_outlet
  }
  if (isAdmin(user)) {
    return local_outlet ? parseInt(local_outlet, 10) : 0
  }
  return 0
}

const fetchTimeout = async (outlet_id: number) => {
  const { data } = await apiClient.get(`/api/outlets/check/${outlet_id}`)
  return data
}

export const useTechTime = (user: AuthUserType) => {
  const outletId = user?.session && user.session.outlet_id ? user.session.outlet_id : 0
  const { data: techTimeData } = useQuery(
    ['techTime', outletId ? outletId : 0],
    () => fetchTimeout(outletId ? outletId : 0),
    {
      enabled: outletId ? true : false,
      refetchInterval: 60000,
    },
  )

  return techTimeData && techTimeData.is_working === false ? true : false
}
