import _map from 'lodash/map'

import apiClient from '../apiClient/apiClient'

export const fetchClientConfigsByClientId = async (client_id: number) => {
  const { data } = await apiClient.get(`/api/barista/clients_configs/${client_id}`)
  return data
    ? _map(data, (item: any) => {
        const parsedData = JSON.parse(item.value)
        if (typeof parsedData.modelName !== 'undefined') {
          return {
            id: item.id,
            client_id: item.client_id,
            car: JSON.parse(item.value),
          }
        }
        return {
          id: item.id,
          client_id: item.client_id,
          product: JSON.parse(item.value),
        }
      })
    : undefined
}

export const addClientConfigsByClientIdRequest = async (client_id: number, sendData: string) => {
  const { data } = await apiClient.post(`/api/barista/clients_configs/${client_id}`, {
    value: sendData,
  })
  return data
}

export const deleteClientConfigByClientIdRequest = async (client_id: number, id: number) => {
  const { data } = await apiClient.delete(`/api/barista/clients_configs/${id}/${client_id}`)
  return data
}

export const fetchClientConfigSearchRequest = async (sendData: any) => {
  const { data } = await apiClient.get('/api/clients_config_search', {
    params: sendData,
  })
  return data
}
