import { Button } from 'application/presentation/common/uiComponents'
import React, { useCallback, useEffect, useState } from 'react'
import _find from 'lodash/find'
import MaskedTextInput from 'application/presentation/common/uiComponents/MaskedTextInput'
import ToastNotify from 'application/app/ToastNotify'
import { getCarByNumber } from 'application/domain/useCase/av100/autoDetectCar'
import { useLocation } from 'react-router'

import s from '../AddCarPage.module.scss'

type Props = {
  carNumber: string
  setCarNumber: Function
  onNumberCheck: Function
}

const SelectCarNumberStage = ({ carNumber, setCarNumber, onNumberCheck }: Props) => {
  const { search } = useLocation()
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)
  const savePressHandler = useCallback(() => {
    const formattedNumber = carNumber.replace(/ /g, '')
    console.log(formattedNumber)
    if (formattedNumber) {
      setSaveIsLoading(true)
      getCarByNumber(formattedNumber)
        .then((responseData) => {
          // console.log(responseData)
          onNumberCheck(responseData)
          setSaveIsLoading(false)
        })
        .catch(() => {
          ToastNotify('Ошибка автоопределения')
          onNumberCheck(null)
          setSaveIsLoading(false)
        })
    }
  }, [carNumber, onNumberCheck])

  useEffect(() => {
    const p = new URLSearchParams(search)
    const num = p.get('fullCarNumber')
    if (num) {
      savePressHandler()
    }
  }, [savePressHandler, search])

  return (
    <div>
      <MaskedTextInput
        value={carNumber}
        onChange={(val: string) => setCarNumber(val.toUpperCase())}
        mask="a 999 aa   999"
        maskChar=""
        placeholder="A 000 AA   21"
        containerClassName={s.car_number_container}
        inputClassName={s.car_number_input}
      />
      <Button
        text="Далее"
        color="fiol"
        containerClassName={s.add_car_btn}
        disabled={carNumber.replace(/\s/g, '').length < 8}
        isLoading={saveIsLoading}
        onClick={savePressHandler}
      />
    </div>
  )
}

export default React.memo(SelectCarNumberStage)
