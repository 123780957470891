import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ru from './locales/ru.json'

const resources = {
  ru: {
    translation: ru,
  },
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'ru',
  saveMissing: true,

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n
