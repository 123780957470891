import axios from 'axios'

const apiClient = axios.create({
  //@ts-ignore
  baseURL: window.ENV && window.ENV.url ? window.ENV.url : 'https://isdon-s4.vsezbs.lol/',
  // window.location.origin === 'http://localhost:3000'
  //   ? 'https://isdon-s2.vsezbs.space/'
  // baseURL: 'https://isdon-s0.vsezbs.space/',
  headers: {
    'Content-Type': 'application/json',
  },
})

export default apiClient
