import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { ReactComponent as DontySvg } from 'images/dontyLogo.svg'
import { NavLink, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import slide1 from 'images/slide1.png'
import { Pagination } from 'swiper'
import _isArray from 'lodash/isArray'
import 'swiper/css/pagination'
import { AuthContext } from 'application/app/AuthContext'
import { getUserRole } from 'application/presentation/UserController'

import s from './WaitingPage.module.scss'

const WaitingPage = () => {
  const navigate = useNavigate()
  const timer = useRef<any>()
  const authContext = useContext(AuthContext)
  const auth = useCallback(() => {
    const phone = localStorage.getItem('phone')
    const password = localStorage.getItem('password')
    authContext.login({
      data: { phone, password },
      onSuccess: (userData: any) => {
        const { user } = userData
        console.log(user)
        if (user && user.owner_id > 0) {
          const role = getUserRole(user)
          if (role.length) {
            if (role === 'admin') {
              navigate('/tutorial')
            } else {
              navigate('/')
            }
          }
        }
      },
    })
  }, [authContext, navigate])

  const startTimer = useCallback(() => {
    if (timer && timer.current) {
      clearTimeout(timer.current)
    }
    auth()
    timer.current = setTimeout(() => startTimer(), 60000)
  }, [auth])

  useEffect(() => {
    startTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={s.page_container}>
      <div className={s.header}>
        <div className={s.left}>
          <DontySvg />
        </div>
      </div>
      <div className={s.content}>
        <div className={s.notice}>
          Хотим поделиться интересными фактами о нас, пока идет установка вашего облака...
        </div>
        <Swiper
          modules={[Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{
            clickable: true,
            horizontalClass: s.pagination,
            bulletClass: s.bullet,
            bulletActiveClass: s.bullet_active,
          }}
        >
          <SwiperSlide>
            <div className={s.slide_content}>
              <div className={s.img_block}>
                <img src={slide1} alt="" />
              </div>
              <div className={s.slide_text}>
                Наша техподдержка доступна 24/7 во всех часовых поясах
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={s.slide_content}>
              <div className={s.img_block}>
                <img src={slide1} alt="" />
              </div>
              <div className={s.slide_text}>
                Наша техподдержка доступна 24/7 во всех часовых поясах
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={s.slide_content}>
              <div className={s.img_block}>
                <img src={slide1} alt="" />
              </div>
              <div className={s.slide_text}>
                Наша техподдержка доступна 24/7 во всех часовых поясах
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={s.footer}>
        <div className={s.footer_item}>Donty</div>
        <div className={s.separator}></div>
        <NavLink className={s.footer_item} to="#">
          Лицензионное соглашение
        </NavLink>
        <div className={s.separator}></div>
        <NavLink className={s.footer_item} to="#">
          Политика конфиденциальности
        </NavLink>
      </div>
    </div>
  )
}

export default React.memo(WaitingPage)
