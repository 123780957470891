/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import s from './InputLabel.module.scss'

type Props = {
  title: string
  name?: string
  type?: string
  icon?: string
  iconContainerClass?: string
  iconClass?: string
  className?: string
  defaultValue?: string
  value: string
  refer?: any
  autocomplete?: string
  readonly?: boolean
  onBlur?: Function
  wrong?: boolean
  onChange: Function
  onKeyUp?: Function
  disableEnter?: boolean
  onlyNumber?: boolean
  nextField?: Function
}
const InputLabel = ({
  icon,
  iconClass,
  iconContainerClass,
  className,
  onChange,
  title,
  refer,
  autocomplete,
  readonly,
  wrong,
  value,
  type,
  onKeyUp,
  disableEnter,
  onlyNumber,
  nextField,
}: Props) => {
  const [focused, setFocused] = useState<boolean>(false)

  const onFocus = useCallback(() => {
    setFocused(true)
  }, [])
  const onBlur = useCallback(() => {
    if (!value || value.length === 0) {
      setFocused(false)
    }
  }, [value])

  useEffect(() => {
    if (value && value.length) {
      onFocus()
    }
  }, [value, onFocus])

  return (
    <label
      className={`${s.input__item} ${s.input_label} ${className ? className : ''} ${
        icon ? s.with_icon : ''
      } ${focused ? s.focus : ''} ${wrong ? s.wrong : ''}`}
    >
      <div className={s.little_text}>{title}</div>
      {icon && (
        <div className={`${s.icon} ${iconContainerClass ? iconContainerClass : ''}`}>
          <img src={icon ? icon : ''} alt="" className={iconClass ? iconClass : ''} />
        </div>
      )}
      {type === 'password' || type === 'email' || type === 'tel' ? (
        <input
          value={value}
          ref={refer ? refer : undefined}
          onChange={(e: any) => {
            if (onChange) onChange(e.target.value)
          }}
          className={s.input}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete={autocomplete ? autocomplete : 'on'}
          readOnly={readonly}
          type={type}
        />
      ) : (
        <TextareaAutosize
          value={value}
          ref={refer ? refer : undefined}
          onChange={(e: any) => {
            if (disableEnter && e.nativeEvent.inputType === 'insertLineBreak') return
            if (onChange) {
              if (onlyNumber) {
                onChange(e.target.value.replace(/\D/, ''))
              } else {
                onChange(e.target.value)
              }
            }
          }}
          className={s.input}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete={autocomplete ? autocomplete : 'on'}
          readOnly={readonly}
          onKeyDown={(e: any) => {
            if (nextField) {
              nextField(e)
            }
          }}
          onKeyUp={() => {
            if (onKeyUp) {
              onKeyUp()
            }
          }}
        />
      )}
    </label>
  )
}

export default React.memo(InputLabel)
