/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { NavLink } from 'react-router-dom'

// import { IconSvg } from '..'

import s from './DropDown.module.scss'

type Props = {
  triggerComponent: Function
  containerClassName?: string
  disabled?: boolean
  data:
    | Array<
        | {
            id: number
            onClick?: Function
            icon?: string
            title: string
            href?: string
            iconClassName?: string
          }
        | undefined
      >
    | undefined
}

const useOutsideClick = (ref: any, setOpened: Function) => {
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpened(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [ref, setOpened])
}

// TODO: посмотреть альтернативный способ вызова react компоненты внутри функции, дабы избавиться от {triggerComponent()}

const DropDownMenu = ({ triggerComponent, data, containerClassName, disabled }: Props) => {
  const wrapperRef = useRef<any>()
  const [opened, setOpened] = useState<boolean>(false)
  useOutsideClick(wrapperRef, setOpened)

  const handleClick = useCallback(() => {
    setOpened((o) => !o)
  }, [])

  return (
    <div
      className={`${s.more_button} ${containerClassName || ''} ${opened ? s.opened : ''} ${
        disabled ? s.disabled : ''
      }`}
      onClick={(e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (!opened && !disabled) handleClick()
      }}
    >
      {triggerComponent()}
      <div className={`${s.dropdown_menu} ${opened ? s.opened : ''}`} ref={wrapperRef}>
        {data ? (
          data.map((item: any) =>
            item ? (
              <NavLink
                to={item.href ? item.href : '#'}
                className={s.item}
                onClick={(e: any) => {
                  if (item.onClick) {
                    item.onClick(item, e)
                  }
                  setOpened(false)
                }}
                key={`dropdown_${item.id}`}
              >
                <div className={s.img_block}>{/* <IconSvg src={item.icon} /> */}</div>
                <div className={s.text}>{item.title}</div>
              </NavLink>
            ) : (
              <></>
            ),
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default React.memo(DropDownMenu)
