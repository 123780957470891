import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import Loader from '../../Loader'

import s from './Button.module.scss'

export type BtnColorType =
  | 'red'
  | 'white'
  | 'transparent'
  | 'gray'
  | 'green'
  | 'orange'
  | 'white_without_shadow'
  | 'yellow'
  | 'fiol'
  | 'dark_gray'
  | 'fiol_with_white_background'

type Props = {
  text: string | React.ReactElement
  color?: BtnColorType
  isLink?: boolean
  to?: string
  replace?: boolean
  containerClassName?: string
  className?: string
  iconUrl?: string
  withoutShadow?: boolean
  onClick?: Function
  isLoading?: boolean
  stopEvents?: boolean
  disabled?: boolean
  btnStyle?: any
  size?: 'large'
  alignUnderInput?: boolean
}

const Button = ({
  text,
  isLink,
  to,
  color,
  containerClassName,
  className,
  iconUrl,
  withoutShadow,
  onClick,
  isLoading,
  stopEvents,
  disabled,
  btnStyle,
  replace,
  size,
  alignUnderInput,
}: Props) => {
  if (isLink) {
    return (
      <div
        className={`${s.btn_container} ${alignUnderInput ? s.alignUnderInput : ''} ${
          containerClassName || ''
        }`}
      >
        <NavLink
          to={to || ''}
          replace={replace}
          className={cn({
            [s.btn]: true,
            [s[color || '']]: !!color,
            [s.with_icon]: !!iconUrl,
            [s.withoutShadow]: withoutShadow,
            [className || '']: !!className,
            [s.isLoading]: isLoading,
            [s.disabled]: disabled,
            [s[`${size}`]]: size ? true : false,
          })}
          style={btnStyle ? btnStyle : {}}
          onClick={(e: any) => {
            if (stopEvents) {
              e.stopPropagation()
            }
          }}
        >
          {isLoading ? (
            <Loader color="#ffffff" size={16} containerClassName={s.loader} />
          ) : (
            <>
              {iconUrl ? <img src={iconUrl} alt="" /> : <></>}
              <span>{text}</span>
            </>
          )}
        </NavLink>
      </div>
    )
  }
  return (
    <div
      className={`${s.btn_container}  ${alignUnderInput ? s.alignUnderInput : ''} ${
        containerClassName || ''
      }`}
    >
      <button
        type="button"
        disabled={isLoading}
        className={cn({
          [s.btn]: true,
          [s[color || '']]: !!color,
          [s.with_icon]: !!iconUrl,
          [s.withoutShadow]: withoutShadow,
          [className || '']: !!className,
          [s.isLoading]: isLoading,
          [s.disabled]: disabled,
          [s[`${size}`]]: size ? true : false,
        })}
        style={btnStyle ? btnStyle : {}}
        onClick={(e: any) => {
          if (stopEvents) {
            e.preventDefault()
            e.stopPropagation()
          }
          if (onClick) {
            onClick()
          }
        }}
      >
        {isLoading ? (
          <Loader color="#ffffff" size={12} containerClassName={s.loader} />
        ) : (
          <>
            {iconUrl ? <img src={iconUrl} alt="" /> : <></>}
            <span>{text}</span>
          </>
        )}
      </button>
    </div>
  )
}

export default React.memo(Button)
