import React, { useState, useCallback, useEffect } from 'react'
import cn from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/store'
import { ReactSVG } from 'react-svg'
import { setCollapseNavMenu } from 'application/domain/store/reducers/navSlice'

import s from './NavigationItems.module.scss'

type ItemType = {
  id: number
  name: string
  t_name?: string
  icon?: any
  defaultOpened?: boolean
  items?: Array<{
    name: string
    link: string
    t_name?: string
  }>
  link?: string
}

type Props = {
  data: Array<ItemType>
}

type ItemProps = {
  data: ItemType
  id: number
  expanded: boolean | number
  setExpanded: (val: boolean | number) => void
  active: number
  setActive: (val: number) => void
}

const Item = React.memo(({ data, id, expanded, setExpanded, active, setActive }: ItemProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { collapseNavMenu } = useSelector(({ navReducer }: RootState) => ({
    collapseNavMenu: navReducer.collapseNavMenu,
  }))

  const { pathname } = useLocation()

  const isOpen = id === expanded

  const linkActive = useCallback(() => {
    let act = false
    data?.items?.forEach((el) => {
      if (el.link !== '/') {
        if (pathname.indexOf(el.link) !== -1) {
          act = true
        }
      } else {
        if (pathname === '/') {
          act = true
        }
      }
    })

    if (act) {
      setActive(data.id)
      setExpanded(data.id)
    }
  }, [data, pathname, setActive, setExpanded])

  useEffect(() => {
    linkActive()
  }, [linkActive])

  const clickHandler = useCallback(
    () => setExpanded(isOpen ? false : id),
    [id, isOpen, setExpanded],
  )

  const iconClickHandker = useCallback(
    (e: any) => {
      if (collapseNavMenu) {
        e.preventDefault()
        e.stopPropagation()
        setCollapseNavMenu()(dispatch)
        setTimeout(() => setExpanded(id), 500)
        setActive(id)
      }
    },
    [collapseNavMenu, dispatch, id, setActive, setExpanded],
  )

  return (
    <>
      <div className={cn(s.item_main, active === data.id ? s.active : {})} onClick={clickHandler}>
        {data.icon ? (
          <ReactSVG
            src={data.icon}
            className={s.item_icon}
            onClick={(e: any) => iconClickHandker(e)}
          />
        ) : (
          <></>
        )}
        <div className={cn(s.item_text, collapseNavMenu ? s.collapse_text : '')}>{data.name}</div>
      </div>
      <AnimatePresence>
        {isOpen && !collapseNavMenu && (
          <motion.div
            variants={{
              open: {
                opacity: 1,
                height: 'auto',
                overflow: 'hidden',
              },
              closed: {
                opacity: 0,
                height: 0,
                overflow: 'hidden',
              },
            }}
            initial="closed"
            animate="open"
            exit="closed"
          >
            {data.items ? (
              data.items.map((item) => (
                <NavLink
                  to={item.link}
                  className={({ isActive }) => cn(s.nav_item, isActive ? s.active : null)}
                  end={item.link === '/'}
                  key={item.link}
                >
                  {/* <div className={s.icon}>{item.icon || <></>}</div> */}
                  <div className={s.name}>{t(`nav.menu.${data.t_name}.links.${item.t_name}`)}</div>
                </NavLink>
              ))
            ) : (
              <></>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
})

const NavigationItems = ({ data }: Props) => {
  const { t } = useTranslation()
  const { collapseNavMenu } = useSelector(({ navReducer }: RootState) => ({
    collapseNavMenu: navReducer.collapseNavMenu,
  }))
  const [expanded, setExpanded] = useState<boolean | number>(1)
  const [active, setActive] = useState<number>(0)

  useEffect(() => {
    if (collapseNavMenu) {
      setExpanded(0)
    }
  }, [collapseNavMenu])

  return (
    <div className={s.nav}>
      {data.map((item) =>
        item.items ? (
          <Item
            data={item}
            key={`nav_group_${item.id}`}
            id={item.id}
            expanded={expanded}
            setExpanded={setExpanded}
            active={active}
            setActive={setActive}
          />
        ) : (
          <NavLink
            to={item.link || ''}
            className={({ isActive }) => cn(s.item_main, isActive ? s.active : null)}
            end={item.link === '/'}
            key={`nav_group_${item.id}`}
          >
            {/* <div className={s.icon}>{item.icon || <></>}</div> */}
            <div className={s.name}>{t(`nav.menu.${item.t_name}.title`)}</div>
          </NavLink>
        ),
      )}
    </div>
  )
}

export default React.memo(NavigationItems)
