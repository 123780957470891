import React, { useContext, useMemo, useState } from 'react'
import Userpic from 'images/user_default.png'
import { useSelector } from 'react-redux'
import { AuthContext } from 'application/app/AuthContext'
import apiClient from 'application/data/apiClient'
import { RootState } from 'application/domain/store/store'
import { useTranslation } from 'react-i18next'
import { getUserRole, isBarista } from 'application/presentation/UserController'

import s from '../Header.module.scss'
import { DropDown } from '../../uiComponents'

const HeadUserBlock = () => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const { user } = useSelector(({ userReducer, navReducer }: RootState) => ({
    user: userReducer.user,
    showNavMenu: navReducer.showNavMenu,
    showHeaderMenu: navReducer.showHeaderMenu,
  }))
  const [userImg, setUserImg] = useState<string>(
    user && user.photo ? `${apiClient.defaults.baseURL}/${user.photo}` : Userpic,
  )

  const userIsBarista = useMemo(() => isBarista(user), [user])

  const dropDownData = useMemo(() => {
    const logout = {
      id: 1,
      title: t('header.exit'),
      onClick: () => authContext.logout(),
    }

    const addBaristaOrAdept = {
      id: 2,
      title: 'Добавить сотрудника в смену',
      href: '#addUserInSession',
    }

    if (userIsBarista) {
      return [logout, addBaristaOrAdept]
    }

    return [logout]
  }, [authContext, t, userIsBarista])
  return (
    <div className={s.right_block}>
      <DropDown
        triggerComponent={() => (
          <div className={s.profile_block}>
            <div className={s.user}>
              <img
                src={userImg}
                onError={() => {
                  setUserImg(Userpic)
                }}
              />
              <div className={s.user_info}>
                <div className={s.user_name}>{user ? user.name : ''}</div>
                <div className={s.stat}>{getUserRole(user)}</div>
              </div>
            </div>
          </div>
        )}
        data={dropDownData}
      />
    </div>
  )
}

export default React.memo(HeadUserBlock)
