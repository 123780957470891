import { MovingAtom } from 'application/domain/types/barista'

import apiClient from '../apiClient'

export const fetchStatBarista = async (date?: string) => {
  const { data } = await apiClient.get(
    `/api/barista/allSells?panel=true${date ? `&date=${date}` : ''}`,
  )
  return data
}

export const fetchClients = async () => {
  const { data } = await apiClient.get('/api/barista/clients?panel=true')
  return data
}

export const fetchStepOneEncashment = async (sendData: any, stepData: any) => {
  const { data } = await apiClient.get('/api/barista/sendEncashment', {
    params: { ...sendData, worker_id: stepData.encashment_type === 1 ? sendData.worker_id : 0 },
  })
  return data
}

export const fetchStepTwoEncashment = async (code: string) => {
  const { data } = await apiClient.get(`/api/barista/saveEncashment`, {
    params: {
      encashment_code: code,
    },
  })
  return data
}

export const deleteClientRequest = async (id: number) => {
  const { data } = await apiClient.delete(`/api/barista/clients/${id}?panel=true`)

  return data
}

export const editClientRequest = async (
  editUserId: number,
  name: string,
  phone: string,
  birthday: string,
  email: string,
  surname: string,
) => {
  const { data } = await apiClient.put(
    `/api/barista/clients/${editUserId}?panel=true&id=${editUserId}&name=${name}&phone=${phone}${
      birthday !== '' ? `&birthday=${birthday}` : ''
    }${email !== '' ? `&email=${email}` : ''}${surname !== '' ? `&surname=${surname}` : ''}`,
  )

  return data
}

export const movingAtomRequest = async (sendData: MovingAtom) => {
  const { data } = await apiClient.post(`api/barista/erp/movingAtom?panel=true`, sendData)

  return data
}

export const checkCallPersonalRequest = async (id: number) => {
  const { data } = await apiClient.post(`/api/barista/client-request-tables/${id}`)
  return data
}

export const fetchTeamRequest = async (isBarista: boolean) => {
  const { data } = await apiClient.post('/api/users/getTeam?panel=true', {
    adept: isBarista ? false : true,
    barista: isBarista ? true : false,
  })

  return data
}

export const addUserInSessionRequest = async (
  isBarista: boolean,
  userId: number,
  tableId?: number,
) => {
  const newData: any = {
    ['users-team']: userId,
  }
  if (isBarista) {
    newData['team-barista'] = 'on'
  } else {
    newData['team-adept'] = 'on'
  }
  if (tableId) {
    newData.table_id = tableId
  }
  const { data } = await apiClient.post('/api/barista/addUserInSession?panel=true', newData)

  return data
}

export const getSessionInfo = async () => {
  const { data } = await apiClient.get('/api/barista/sessionInfo?panel=true')

  return data
}

export const deleteUserInSessionRequest = async (extraSessionId: number) => {
  const newData: any = {
    extraSessionId: extraSessionId,
    ['team-adept']: 'on',
  }

  const { data } = await apiClient.post('/api/barista/finishTeamSession?panel=true', newData)

  return data
}

export const createDonSessionLogRequest = async (sendData: {
  key: string
  value: string
  status: string
  don_session_id: number
}) => {
  const { data } = await apiClient.post('/api/barista/don_session_logs', sendData)
  return data
}
