/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ProductType } from 'types/catalogType'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { getDefaultOutlet } from 'application/presentation/UserController'
import { RootState } from 'application/domain/store/store'
import { useProduct } from 'application/domain/useCase/products/products'

import MWContainer from '../MWContainer'
import { useTheme } from '../../Hooks/useTheme'
import Loader from '../../Loader'

import s from './ProductMW.module.scss'
import ProductMwContent from './ProductMwContent'
// import AdditionalItem from './AdditionalItem'

const ProductMW = () => {
  const { hash, search } = useLocation()
  const navigate = useNavigate()
  const { basketData, user, editProduct } = useSelector(
    ({ userReducer, basketReducer }: RootState) => ({
      basketData: basketReducer.basket,
      user: userReducer.user,
      editProduct: basketReducer.editProduct,
    }),
  )
  const opened = useMemo(() => {
    return hash.indexOf('select_product') !== -1
  }, [hash])

  const { isLightTheme } = useTheme()

  const outlet_id = useMemo(() => getDefaultOutlet(user), [user])

  const edit_product_id = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const id = searchParams.get('edit_product_id')
    return parseInt(id ? id : '0', 10)
  }, [search])

  const product_id = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const id = searchParams.get('product_id')
    if (edit_product_id && editProduct && edit_product_id === parseInt(editProduct.uniq_id, 10)) {
      return editProduct.id
    }
    return parseInt(id ? id : '0', 10)
  }, [search, editProduct, edit_product_id])

  const { data, isLoading } = useProduct(product_id, outlet_id, {
    enabled: opened && product_id !== 0 ? true : false,
    refetchOnWindowFocus: false,
  })

  const product: ProductType = useMemo(() => {
    if (editProduct && edit_product_id && data && data.product) {
      console.log(editProduct)
      return {
        ...data.product,
        varieties: editProduct.varieties,
        discont: editProduct.discont || 0,
        uniq_id: editProduct.uniq_id,
        uniq_ids: editProduct.uniq_ids,
        temperature: editProduct.temperature,
        options:
          editProduct.options && editProduct.options.length
            ? editProduct.options
            : data.product.options,
      }
    }
    return data && data.product ? data.product : null
  }, [data, editProduct, edit_product_id])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (hash.indexOf('product') !== -1) {
          navigate(-1)
        }
      }}
      className={`${s.modal_window_desk} ${isLightTheme ? s.light : ''}`}
      btnClassName={s.close_btn}
    >
      <>
        {product_id ? (
          <>
            {isLoading ? (
              <Loader color={isLightTheme ? '#ff7a00' : '#ffffff'} />
            ) : product ? (
              <ProductMwContent
                opened={opened}
                product={product}
                basketData={basketData}
                isEdit={edit_product_id ? true : false}
              />
            ) : (
              <div className={s.subtitle}>Товар не найден</div>
            )}
          </>
        ) : edit_product_id ? (
          <>
            {/* {editProductData ? (
              <ProductMwContent
                opened={opened}
                product={editProductData}
                basketData={basketData}
                isEdit
              />
            ) : (
              <div className={s.subtitle}>Товар не найден</div>
            )} */}
          </>
        ) : (
          <div className={s.subtitle}>Товар не найден</div>
        )}
      </>
    </MWContainer>
  )
}

export default React.memo(ProductMW)
