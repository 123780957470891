import React, { useCallback } from 'react'
import { ReactComponent as BackSvg } from 'images/icons/back_long_arrow.svg'
import { useNavigate } from 'react-router'

import s from './SimpleMobileHeader.module.scss'

type Props = {
  title: string
  onBackClick?: Function
}

const SimpleMobileHeader = ({ title, onBackClick }: Props) => {
  const navigate = useNavigate()
  const onBackClickHandler = useCallback(() => {
    if (onBackClick) {
      return onBackClick()
    }
    return navigate(-1)
  }, [navigate, onBackClick])

  return (
    <div className={s.container}>
      <div className={s.left} onClick={onBackClickHandler}>
        <BackSvg />
      </div>
      <div className={s.center}>{title}</div>
      <div className={s.right}></div>
    </div>
  )
}

export default React.memo(SimpleMobileHeader)
