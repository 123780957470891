/* eslint-disable @typescript-eslint/naming-convention */

import React, { useContext, useEffect, useState } from 'react'
import { NavLink, Route, Routes, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { InitUser, useTechTime } from 'application/presentation/UserController'
import { ToastContainer, Zoom } from 'react-toastify'
import { Portal } from 'react-portal'
import AuthPage from 'application/presentation/pages/commonPages/AuthPage'
import MainNavigation from 'application/presentation/navigation/MainNavigation'
import { AuthContext } from 'application/app/AuthContext'
import apiClient from 'application/data/apiClient'
import { RootState } from 'application/domain/store/store'
import { User } from 'application/domain/entity/user/User'

import s from './AppView.module.scss'
import Loader from './common/Loader'
import { IframeMW } from './common/ModalWindows'
import WaitingPage from './pages/commonPages/WaitingPage'
import AddBaristaOrAdeptMW from './common/ModalWindows/AddBaristaOrAdeptMW'
import OutletsAvailabilityListPage from './pages/commonPages/OutletsAvailabilityListPage/OutletsAvailabilityListPage'
import ProductsAvailableInOutletsPage from './pages/adminPages/ProductsAvailableInOutletsPage/ProductsAvailableInOutletsPage'

const AppView = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const authContext = useContext(AuthContext)
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))

  const isTechBreak = useTechTime(user)

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        const url = localStorage.getItem('url')
        if (url) {
          apiClient.defaults.baseURL = url
        }
        const usr: { user: User } = await InitUser(authContext, dispatch)
      } catch (e) {
        console.log(e)
      }
    }
    bootstrapAsync()
      .then(() => {
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className={s.app_container}>
        {isTechBreak && pathname !== '/outlets_availability' ? (
          <NavLink to="/#timeout" className={s.tech_time} end>
            Технический перерыв
          </NavLink>
        ) : (
          <></>
        )}
        <Routes>
          <Route path="login" element={<AuthPage />} />
          <Route path="registration" element={<AuthPage />} />
          <Route path="waiting" element={<WaitingPage />} />
          <Route path="password/reset/:token" element={<AuthPage />} />
          <Route path="outlets_availability" element={<OutletsAvailabilityListPage />} />
          <Route path="products_availability" element={<ProductsAvailableInOutletsPage />} />
          <Route path="/*" element={<MainNavigation />} />
        </Routes>
      </div>
      <AddBaristaOrAdeptMW />
      <IframeMW />
      <Portal>
        <ToastContainer transition={Zoom} limit={1} autoClose={100} />
      </Portal>
    </>
  )
}

export default React.memo(AppView)
