import { useOutlets } from 'application/domain/useCase/outlet/outlet'
import Loader from 'application/presentation/common/Loader/Loader'
import React, { useMemo } from 'react'
import { Outlet } from 'application/domain/entity/outlet/Outlet'
import _map from 'lodash/map'
import cn from 'classnames'
import { getTechnicalBreak } from 'application/domain/useCase/outlet/outletActions'
import convertRemToPixels from 'application/presentation/common/PureFunctions/remToPixels'
import moment from 'moment'
import { useOutletsAverageStatistics } from 'application/domain/useCase/statistics/getStatistics'
import _find from 'lodash/find'
import { secondsToMinutesAndSeconds } from 'application/utils/timeConverter'
import { NavLink } from 'react-router-dom'

import s from './OutletsAvailabilityListPage.module.scss'

const OutletsAvailabilityListPage = () => {
  const { data, isLoading } = useOutlets({
    refetchInterval: 600000,
  })
  const { data: averageStatsData, isLoading: averageStatsIsLoading } = useOutletsAverageStatistics({
    refetchInterval: 60000,
    retry: false,
  })

  const outlets = useMemo(() => {
    if (data && averageStatsData) {
      const stat =
        averageStatsData[Object.keys(averageStatsData)[Object.keys(averageStatsData).length - 1]]
      return data
        ? _map(data.outlets, (item) => ({
            ...item,
            averageStats: _find(stat, { outlet_id: item.id }),
          }))
        : []
    }
    return data ? data.outlets : []
  }, [data, averageStatsData])

  const elemData = useMemo(() => {
    const elemCount = outlets.length
    if (elemCount) {
      const { innerWidth, innerHeight } = window
      let width = innerWidth
      let height = innerHeight
      const square = (width * height) / elemCount // площадь одного элемента
      const sqrt = Math.floor(Math.sqrt(square)) // ширина стороны элемента
      const elemCountInRow = Math.floor(width / sqrt)
      const elemCountInColumn = Math.floor(height / sqrt)
      if (elemCountInRow * elemCountInColumn < elemCount) {
        let elemWidth = sqrt
        let inRow = elemCountInRow
        let inColumn = elemCountInColumn
        while (inRow * inColumn < elemCount) {
          elemWidth = elemWidth - 1
          inColumn = Math.floor(height / elemWidth)
          inRow = Math.floor(width / elemWidth)
        }
        return {
          width: elemWidth,
          elemCountInRow: inRow,
        }
      }

      return { width: sqrt, elemCountInRow }
    }
    return { width: 0, elemCountInRow: 0 }
  }, [outlets])

  return isLoading || averageStatsIsLoading ? (
    <Loader />
  ) : (
    <div className={s.container}>
      {outlets.map((item: Outlet & { averageStats: any }, index: number) => {
        const techBreak = getTechnicalBreak(item)
        const sessionIsFinished =
          item.don_session_finish &&
          item.don_session_finish !== '0000-00-00 00:00:00' &&
          moment().isAfter(moment(item.don_session_finish))
            ? true
            : false
        const time = item.averageStats ? secondsToMinutesAndSeconds(item.averageStats.time) : null
        return (
          <NavLink
            className={cn(s.item, { [s.closed]: techBreak || sessionIsFinished ? true : false })}
            style={{
              width: elemData.width,
              paddingBottom: elemData.width,
            }}
            key={item.id}
            to={`/result/all_stats?outlet_id=${item.id}`}
          >
            <div className={s.name}>
              {item.name}
              {techBreak ? (
                <p className={s.paragraph}>
                  Тех.перерыв
                  <br />
                  {techBreak}
                </p>
              ) : sessionIsFinished ? (
                <p className={s.paragraph}>
                  Закрыта с
                  <br />
                  {moment(item.don_session_finish).format('DD.MM.YY HH:mm')}
                </p>
              ) : (
                ''
              )}
              <p className={s.paragraph}>
                По заказам:
                <br />
                {item.averageStats ? (
                  <>
                    Кол-во: {item.averageStats.orders_count}
                    <br />
                    {time ? `Время: ${time.minutes}мин ${time.seconds}сек` : ''}
                  </>
                ) : (
                  'нет данных'
                )}
              </p>
            </div>
          </NavLink>
        )
      })}
    </div>
  )
}

export default React.memo(OutletsAvailabilityListPage)
