import React from 'react'
import { ReactComponent as InfoIcon } from 'images/icons/circle_info_icon.svg'

import s from './InfoNotification.module.scss'

type Props = {
  text: string | React.ReactNode
}

const InfoNotification = ({ text }: Props) => {
  return (
    <div className={s.info}>
      <div className={s.info_icon}>
        <InfoIcon />
      </div>
      <div className={s.info_txt}>{text}</div>
    </div>
  )
}

export default React.memo(InfoNotification)
