import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { VarietyType } from 'types/catalogType'

import s from '../ProductMW.module.scss'

import OptionItem from './OptionItem'
import ProductItem from './ProductItem'

type Props = {
  selectAdditional: Function
  additionals: any
  selectedVolume: number
}

type ItemProps = {
  item: any
  selectAdditional: Function
  additionals: any
  selectedVolume: number
}

const Item = ({ item, selectAdditional, additionals, selectedVolume }: ItemProps) => {
  const [state, setState] = useState(0)

  const selected = useMemo(() => {
    let id = 0
    item.items.map((v: VarietyType) => {
      if (v.selected === true) {
        id = v.id
      }
    })
    return id
  }, [item])

  return (
    <div className={s.adds_container}>
      <ProductItem
        color="orange"
        title={'Не выбрано'}
        id={0}
        active={selected}
        setActive={setState}
        onChange={() => {
          selectAdditional(_.find(additionals, { id: item.id }), 0, 0)
        }}
      />
      {item.items.map((prod: any) => {
        let defined = false

        if (prod?.item_to_variety?.length) {
          prod.item_to_variety.forEach((variety: any) => {
            if (variety.product_variety_id === selectedVolume) {
              defined = true
            }
          })
        } else if (prod.item_to_variety && _.isEmpty(prod.item_to_variety.length)) {
          defined = true
        }

        if (defined) {
          return (
            <OptionItem
              key={prod.id}
              color="orange"
              title={prod.name}
              price={prod.cost}
              selectAdditional={selectAdditional}
              additionals={additionals}
              count={prod.count}
              item={item}
              option={prod}
            />
          )
        }
      })}
    </div>
  )
}

const Prod = ({ selectAdditional, additionals, selectedVolume }: Props) => {
  return (
    <>
      {additionals.map((item: any) => {
        if (item.items.length > 1) {
          return (
            <div key={item.id}>
              <div className={s.subtitle}>{item.name}</div>
              <Item
                item={item}
                selectAdditional={selectAdditional}
                selectedVolume={selectedVolume}
                additionals={additionals}
              />
            </div>
          )
        }
        return null
      })}
    </>
  )
}

export default React.memo(Prod)
