import { Product } from 'application/domain/entity/product/Product'
import React, { useCallback, useMemo } from 'react'
import { UpdateProductOptionStatusMutation } from 'application/domain/useCase/products/productsMutations'

import { UniversalInputComponent } from '../../InputComponents'

import s from './EditProductAvailabilityMW.module.scss'

type Props = {
  product: Product
  outletId: number
}

const EditAvailabilityContent = ({ product, outletId }: Props) => {
  const varieties = useMemo(() => product.varieties, [product])
  const options = useMemo(() => product.options, [product])
  const mutation = UpdateProductOptionStatusMutation()

  const onItemClickHandler = useCallback(
    (id: number, currentStatus?: string) => {
      const status = currentStatus === 'in_stock' ? 'not_available' : 'in_stock'
      mutation.mutate({
        product_id: product.id,
        outlet_id: outletId,
        option_id: id,
        status: status,
      })
    },
    [mutation, outletId, product.id],
  )

  return (
    <div className={s.edit_container}>
      <div className={s.item}>
        <div className={s.row_title}>Объемы</div>
        <div className={s.row}>
          {varieties.map((item) => (
            <div
              className={s.row_item}
              key={`variety_${item.id}`}
              onClick={() => onItemClickHandler(item.variety_id, item.availability_status)}
            >
              <div className={s.item_title}>{item.name}</div>
              <div className={s.item_cost}>{item.cost} руб</div>
              <UniversalInputComponent
                type="switch"
                value={item.availability_status === 'in_stock'}
                onChange={() => onItemClickHandler(item.variety_id, item.availability_status)}
                inputContainerClassName={s.switch_container}
                inputClassName={s.switch}
              />
            </div>
          ))}
        </div>
        {options.map((item) => (
          <div className={s.item} key={`item_${item.id}`}>
            <div className={s.row_title}>{item.name}</div>
            <div className={s.row}>
              {item.items.map((option) => (
                <div
                  className={s.row_item}
                  key={`option_${option.id}`}
                  onClick={() => onItemClickHandler(option.variety_id, option.availability_status)}
                >
                  <div className={s.item_title}>{option.name}</div>
                  <div className={s.item_cost}>{option.cost} руб</div>
                  <UniversalInputComponent
                    type="switch"
                    value={option.availability_status === 'in_stock'}
                    onChange={() =>
                      onItemClickHandler(option.variety_id, option.availability_status)
                    }
                    inputContainerClassName={s.switch_container}
                    inputClassName={s.switch}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(EditAvailabilityContent)
