import { useTheme } from 'application/presentation/common/Hooks/useTheme'
import React, { useEffect } from 'react'
import Switch from 'react-ios-switch'

import s from './SwitchItem.module.scss'

type SwitchItemProps = {
  checked: boolean | number
  setChecked: (val: any) => void
  title: string
  price: number | string
  onClick?: Function
  data: any
  temp?: boolean
}

const SwitchItem = ({
  checked,
  setChecked,
  title,
  price,
  onClick,
  data,
  temp,
}: SwitchItemProps) => {
  const switchHandler = (e: any) => {
    e.preventDefault()
    setChecked((c: any) => !c)
  }

  const { isLightTheme } = useTheme()
  useEffect(() => {
    if (data) {
      setChecked(data.selected)
    }
  }, [data, setChecked])

  return (
    <div
      className={`${s.item} ${isLightTheme ? s.light : ''}`}
      onClick={(e: any) => {
        if (onClick) {
          onClick(data.id && !checked ? data.id : 0)
        }
        switchHandler(e)
      }}
    >
      <div className={s.item_info}>
        <div className={s.title}>{title}</div>
        <div className={s.price}>
          {temp ? <>{checked ? 'Горячий' : 'Теплый'}</> : <>{`+ ${price} руб`}</>}
        </div>
      </div>
      <Switch
        checked={checked}
        className={`${s.switch} ${checked ? s.checked : ''}`}
        onChange={(e: any) => {
          e.stopPropagation()
          switchHandler(e)
        }}
      />
    </div>
  )
}

export default React.memo(SwitchItem)
