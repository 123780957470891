const getOrderStatus = (status: string) => {
  switch (status) {
    case 'Забронирован':
      return 'created'
    case 'Новый заказ':
      return 'created'
    case 'Принят':
      return 'accepted'
    case 'Начали готовить':
      return 'started'
    case 'Приготовлен':
      return 'done'
    case 'Завершен':
      return 'completed'
    default:
      return 'created'
  }
}

export const getOrderNewStatus = (status: string) => {
  switch (status) {
    case 'Новый заказ':
      return { status: 'accepted', rusStatus: 'Принят' }
    case 'Принят':
      return { status: 'started', rusStatus: 'Начали готовить' }
    case 'Начали готовить':
      return { status: 'done', rusStatus: 'Приготовлен' }
    case 'Приготовлен':
      return { status: 'completed', rusStatus: 'Завершен' }
    case 'Завершен':
      return { status: 'completed', rusStatus: 'Завершен' }
    default:
      return { status: 'accepted', rusStatus: 'Принят' }
  }
}

export default getOrderStatus
