import _find from 'lodash/find'

import apiClient from '../apiClient/apiClient'

export const fetchCarByNumber = async (carNumber: string) => {
  const { data } = await apiClient.get(`/api/vin?number=${carNumber}`)
  if (data && data.result && data.result[0]) {
    const { data: fullCarData } = await apiClient.get(`/api/fullavto?vin=${data.result[0]}`)
    if (fullCarData && fullCarData.result) {
      const mark = _find(fullCarData.result, { id: 'make' })
      const model = _find(fullCarData.result, { id: 'model' })
      return {
        carName: mark?.value,
        carModel: model?.value,
        carBodyName: model?.value,
      }
    }
    const { data: carData } = await apiClient.get(`/api/avto?vin=${data.result[0]}`)
    if (carData && carData.decode && carData.decode.Reports && carData.decode.Reports[0].Data) {
      const car = carData.decode.Reports[0].Data
      if (car.Brand && (car.BodyName || car.Model)) {
        return {
          carName: car.Brand,
          carModel: car.Model,
          carBodyName: car.BodyName,
        }
      }
    }

    return undefined
  }
  return undefined
}
