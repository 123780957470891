import moment from 'moment'

export const millisToMinutesAndSeconds = (millis: number) => {
  var minutes = Math.floor(millis / 60000)
  var seconds = ((millis % 60000) / 1000).toFixed(0)
  return { minutes: minutes.toString(), seconds: (parseInt(seconds, 10) < 10 ? '0' : '') + seconds }
}

export const secondsToMinutesAndSeconds = (sec: number) => {
  var minutes = Math.floor(sec / 60)
  const seconds = sec - minutes * 60
  return { minutes: minutes.toString(), seconds: (seconds < 10 ? '0' : '') + seconds }
}

export const getTimeDiff = (startTime: string, productionTime: number, asTimer?: boolean) => {
  const now = moment()
  const start = moment(startTime)
  const end = moment(startTime).add(productionTime, 'seconds')
  if (asTimer) {
    if (now.isBetween(start, end)) {
      const diff = now.valueOf() - start.valueOf()
      return millisToMinutesAndSeconds(diff)
    }
    return {
      minutes: '0',
      seconds: '0',
    }
  }
  if (now.isBetween(start, end)) {
    const diff = end.valueOf() - now.valueOf()
    return millisToMinutesAndSeconds(diff)
  }
  if (now.isBefore(start)) {
    const diff = start.valueOf() - now.valueOf()
    return { ...millisToMinutesAndSeconds(diff), isBefore: true }
  }
  if (now.isAfter(end)) {
    const diff = now.valueOf() - end.valueOf()
    return { ...millisToMinutesAndSeconds(diff), isAfter: true }
  }
  return {
    minutes: '0',
    seconds: '0',
  }
}
