import { AuthContext } from 'application/app/AuthContext'
import { User } from 'application/domain/entity/user/User'
import { RootState } from 'application/domain/store/store'
import { InputLabel } from 'application/presentation/common/InputComponents'
import { Button, ErrorMsg } from 'application/presentation/common/uiComponents'
import { isUserHaveRole } from 'application/presentation/UserController'
import { getDomain } from 'application/utils/urlUtils'
import React, { useCallback, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import s from '../AuthPage.module.scss'

const AuthForm = () => {
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const [phone, setPhone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [isTechSettings, setIsTechSettings] = useState<boolean>(false)
  const [isPassReset, setIsPassReset] = useState<boolean>(false)
  const [passResetRequestSended, setPassResetRequestSended] = useState<boolean>(false)
  const [apiUrl, setApiUrl] = useState(localStorage.getItem('url') || '')

  const { user } = useSelector(({ userReducer }: RootState) => ({ user: userReducer.user }))
  const auth = useCallback(() => {
    authContext.login({
      data: { phone, password },
      onStart: () => setIsLoading(true),
      onSuccess: (response: { token: string; user: User }) => {
        const domain = getDomain(window.location.origin, true)
        if (domain === 'isdon.ru') {
          window.location.href = `https://${response.user.owner_url}.${domain}/waiting?user_token=${response.token}&login=${phone}&password=${password}`
        } else {
          if (
            isUserHaveRole(
              //@ts-ignore
              response.user,
            )
          ) {
            navigate('/', { replace: true })
          } else {
            navigate('/waiting', { replace: true })
          }
        }
        setIsLoading(false)
      },
      onError: (msg: string) => {
        setIsLoading(false)
        setError(msg)
        console.log(msg)
      },
    })
  }, [authContext, phone, password, navigate])

  const saveTechSettings = useCallback(() => {
    localStorage.setItem('url', apiUrl)
    window.location.reload()
    setIsTechSettings(false)
  }, [apiUrl])

  const onPassResetClickHandler = useCallback(() => {
    authContext.sendPasswordResetRequest({
      data: {
        email: phone,
      },
      onStart: () => setIsLoading(true),
      onError: (msg: string) => {
        setIsLoading(false)
        setError(msg)
        console.log(msg)
      },
      onSuccess: () => {
        setIsLoading(false)
        setPassResetRequestSended(true)
      },
    })
  }, [phone, authContext])

  return (
    <div className={s.form}>
      <div className={s.title}>{isPassReset ? 'Восстановление пароля' : 'Авторизация'}</div>
      <div className={s.fields}>
        {isTechSettings ? (
          <>
            <InputLabel title={'ApiUrl'} value={apiUrl} onChange={setApiUrl} />
            <Button
              text="Сохранить"
              containerClassName={s.big_btn}
              color={'orange'}
              onClick={saveTechSettings}
            />
          </>
        ) : isPassReset ? (
          passResetRequestSended ? (
            <div className={s.notice}>
              Ссылка на восстановление пароля успешно отправлена на почту
              <br />
              <br />
              <b>{phone}</b>
            </div>
          ) : (
            <>
              <InputLabel title={'Введите email'} value={phone} onChange={setPhone} type="email" />
              {error ? <ErrorMsg text={error} /> : <></>}
              <Button
                text="Отправить ссылку!"
                containerClassName={s.big_btn}
                color={'orange'}
                isLoading={isLoading}
                disabled={isLoading}
                onClick={onPassResetClickHandler}
              />
            </>
          )
        ) : (
          <>
            <InputLabel
              title={'Email или телефон'}
              value={phone}
              onChange={setPhone}
              type="email"
            />
            <InputLabel title={'Пароль'} value={password} onChange={setPassword} type="password" />
            {error ? <ErrorMsg text={error} /> : <></>}
            <Button
              color="transparent"
              text="Восстановить пароль"
              className={s.pass_reset_btn}
              onClick={() => setIsPassReset(true)}
            ></Button>
            <Button
              text="Поехали!"
              containerClassName={s.big_btn}
              color={'orange'}
              isLoading={isLoading}
              disabled={isLoading}
              onClick={auth}
            />
            <Button
              color="red"
              text="Технические настройки"
              containerClassName={s.big_btn}
              onClick={() => setIsTechSettings(true)}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default React.memo(AuthForm)
