import React, { useCallback, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import _find from 'lodash/find'
import _map from 'lodash/map'
import { ReactComponent as BackSvg } from 'images/icons/back_arrow.svg'
import {
  formatStringToCarNumber,
  generateCarModel,
} from 'application/domain/useCase/car/carActions'
import { Car } from 'application/domain/entity/car/Car'
import moment from 'moment'
import { getRandomInRange } from 'application/utils/getRandomInRange'
import { addClientConfig } from 'application/domain/useCase/clientConfigs/clientConfigsAction'
import queryClient from 'application/data/apiClient/queryClient'
import ToastNotify from 'application/app/ToastNotify/ToastNotify'

import carsDB from '../../../../../data/localData/cars.json'

import CarSubmitStage from './stages/CarSubmitStage'
import SelectCarNumberStage from './stages/SelectCarNumberStage'
import SelectCarModelStage from './stages/SelectCarModelStage'
import s from './AddCarPage.module.scss'
import SelectCarStage from './stages/SelectCarStage'

type Props = {
  clientId: number
  onAddCar?: Function
}

const AddCarPage = ({ onAddCar, clientId }: Props) => {
  const navigation = useNavigate()
  const { search } = useLocation()
  const [selectNumberStage, setSelectNumberStage] = useState<boolean>(false)
  const [carsModelsList, setCarsModelsList] = useState<any>([])
  const [selectedCarId, setSelectedCarId] = useState<string | number>(0)
  const [selectedCarModelId, setSelectedCarModelId] = useState<string | number>(0)
  const [carNumber, setCarNumber] = useState<string>('')
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)
  const [fetchedCar, setFetchedCar] = useState<{ name: string; modelName: string; number: string }>(
    {
      name: '',
      modelName: '',
      number: '',
    },
  )
  const [numberIsChecked, setNumberIsChecked] = useState<boolean>(false)
  const [manualAddCar, setManualAddCar] = useState<boolean>(false)

  const onNextPressHandler = useCallback(() => {
    setSelectNumberStage(true)
  }, [])
  const onAddCarClickHandler = useCallback(
    () => (onAddCar ? onAddCar() : navigation(-1)),
    [navigation, onAddCar],
  )

  const onBackClickHandler = useCallback(() => {
    if (selectNumberStage) {
      setSelectNumberStage(false)
    } else if (selectedCarId) {
      setSelectedCarId(0)
      setSelectedCarModelId(0)
    } else {
      navigation(-1)
    }
  }, [navigation, selectNumberStage, selectedCarId])

  const onNumberCheckHandler = useCallback(
    (responseData: any) => {
      if (responseData) {
        setManualAddCar(false)
        if (responseData.carModel && (responseData.carName || responseData.carBodyName)) {
          const car = responseData
          const findedCar = _find(
            carsDB,
            (item) => item.name.toUpperCase().indexOf(car.carName.toUpperCase()) !== -1,
          )
          if (findedCar) {
            const findedModel = _find(
              findedCar.models,
              (item) =>
                item.name.toUpperCase() === car.carModel.toUpperCase() ||
                item.name.toUpperCase() === car.carBodyName.toUpperCase(),
            )
            if (findedModel) {
              setSelectedCarId(findedCar.id)
              setSelectedCarModelId(findedModel.id)
              setFetchedCar({
                name: findedCar.name,
                modelName: findedModel.name,
                number: formatStringToCarNumber(carNumber),
              })
            } else {
              setFetchedCar({ name: '', modelName: '', number: '' })
              setManualAddCar(true)
            }
          }
        } else {
          setManualAddCar(true)
        }
      } else {
        setManualAddCar(true)
      }
      setNumberIsChecked(true)
    },
    [carNumber],
  )

  const onCarSavePressHandler = useCallback(() => {
    setSaveIsLoading(true)
    const carModel = generateCarModel({
      carId: selectedCarId,
      modelId: selectedCarModelId,
      carNumber: carNumber,
    })
    if (carModel) {
      if (clientId) {
        addClientConfig(clientId, JSON.stringify(carModel))
          .then(() => {
            queryClient.invalidateQueries(['client_configs']).then(() => {
              setSaveIsLoading(false)
              onAddCarClickHandler()
            })
          })
          .catch(() => {
            ToastNotify('Не удалось сохранить конфигурацию')
            setSaveIsLoading(false)
          })
      } else {
        localStorage.setItem('localSavedCar', JSON.stringify(carModel))
        onAddCarClickHandler()
      }
    } else {
      ToastNotify('Не удалось сохранить конфигурацию')
      setSaveIsLoading(false)
    }
  }, [carNumber, clientId, onAddCarClickHandler, selectedCarId, selectedCarModelId])

  const onManualPressHandler = useCallback(() => {
    setSelectedCarId(0)
    setSelectedCarModelId(0)
    setManualAddCar(true)
  }, [])

  useEffect(() => {
    const p = new URLSearchParams(search)
    const fullCarNumber = p.get('fullCarNumber')
    if (fullCarNumber) {
      setCarNumber(fullCarNumber)
    }
  }, [search])

  useEffect(() => {
    if (selectedCarId) {
      const car = _find(carsDB, (item) => item.id === selectedCarId)
      if (car) {
        const list = _map(car.models, (item) => ({
          id: item.id,
          label: `${item.name}`,
          value: item.id,
          data: item,
        }))
        setCarsModelsList(list)
      } else {
        setCarsModelsList([])
      }
    }
  }, [selectedCarId])

  return (
    <>
      <div className={s.title}>
        <BackSvg className={s.title_icon} onClick={onBackClickHandler} />
        {!numberIsChecked
          ? 'Введите номер машины'
          : !manualAddCar
          ? ''
          : !selectedCarId
          ? 'Выберите марку'
          : 'Выберите модель'}
      </div>

      <div className={s.container}>
        {!numberIsChecked ? (
          <SelectCarNumberStage
            carNumber={carNumber}
            setCarNumber={setCarNumber}
            onNumberCheck={onNumberCheckHandler}
          />
        ) : (
          <>
            {manualAddCar ? (
              <>
                {selectedCarId ? (
                  <SelectCarModelStage
                    carsDB={carsDB}
                    setSelectedCarModelId={setSelectedCarModelId}
                    selectedCarId={selectedCarId}
                    selectedCarModelId={selectedCarModelId}
                    onNextPressHandler={onCarSavePressHandler}
                    saveIsLoading={saveIsLoading}
                  />
                ) : (
                  <SelectCarStage carsDB={carsDB} setSelectedCarId={setSelectedCarId} />
                )}
              </>
            ) : (
              <CarSubmitStage
                onNextPressHandler={onCarSavePressHandler}
                saveIsLoading={saveIsLoading}
                fetchedCar={fetchedCar}
                onManualPressHandler={onManualPressHandler}
              />
            )}
          </>
        )}
      </div>

      {/* <div className={s.container}>
        {!selectedCarId && <SelectCarStage carsDB={carsDB} setSelectedCarId={setSelectedCarId} />}
        {selectNumberStage ? (
          <SelectCarNumberStage
            carNumber={carNumber}
            setCarNumber={setCarNumber}
            carsModelsList={carsModelsList}
            selectedCarId={selectedCarId}
            selectedCarModelId={selectedCarModelId}
            onSuccess={onAddCarClickHandler}
            clientId={clientId}
          />
        ) : selectedCarId ? (
          <SelectCarModelStage
            carsDB={carsDB}
            setSelectedCarModelId={setSelectedCarModelId}
            selectedCarId={selectedCarId}
            selectedCarModelId={selectedCarModelId}
            onNextPressHandler={onNextPressHandler}
          />
        ) : (
          <></>
        )}
      </div> */}
    </>
  )
}

export default React.memo(AddCarPage)
