import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

type PlanState = {
  currentDate: string | null
  date: string | null
  data: any
}

const initialState: PlanState = {
  currentDate: null,
  date: null,
  data: {},
}

export const planReducer = createSlice({
  name: 'planReducer',
  initialState,
  reducers: {
    setPlanDataAction: (state, { payload }: PayloadAction<PlanState>) => {
      state.currentDate = payload.currentDate
      state.date = payload.date
      state.data = payload.data
    },
  },
})

const { setPlanDataAction } = planReducer.actions

export const setPlanData = (payload: PlanState) => (dispatch: Dispatch) =>
  dispatch(setPlanDataAction(payload))

export default planReducer.reducer
