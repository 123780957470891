import React, { useMemo, useState } from 'react'
import _sortBy from 'lodash/sortBy'
import _filter from 'lodash/filter'
import cn from 'classnames'
import SearchInput from 'application/presentation/common/uiComponents/SearchInput'

import s from '../AddCarPage.module.scss'

const topCarIds = [
  'VAZ',
  'AUDI',
  'BMW',
  'CHANGAN',
  'CHERY',
  'CHEVROLET',
  'DAEWOO',
  'CHERYEXEED',
  'FORD',
  'GEELY',
  'HAVAL',
  'HONDA',
  'HYUNDAI',
  'JETOUR',
  'KIA',
  'LAND_ROVER',
  'LEXUS',
  'MAZDA',
  'MERCEDES',
  'MITSUBISHI',
  'NISSAN',
  'OMODA',
  'OPEL',
  'PEUGEOT',
  'PORSCHE',
  'RENAULT',
  'SKODA',
  'SUBARU',
  'SUZUKI',
  'TOYOTA',
  'VOLKSWAGEN',
  'VOLVO',
  'GAZ',
  'UAZ',
]

const SelectCarStage = ({ carsDB, setSelectedCarId }: any) => {
  const [searchVal, setSearchVal] = useState<string>('')
  const topCars = useMemo(
    () =>
      _sortBy(
        _filter(carsDB, (item) => topCarIds.indexOf(item.id) !== -1),
        'name',
      ),
    [carsDB],
  )
  const allCars = useMemo(() => _sortBy(carsDB, 'name'), [carsDB])
  const filteredCars = useMemo(
    () =>
      _sortBy(
        _filter(carsDB, (item) => item.name.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1),
        'name',
      ),
    [carsDB, searchVal],
  )
  return (
    <div>
      <SearchInput
        value={searchVal}
        onChange={setSearchVal}
        placeholder="Введите марку автомобиля"
      />
      {!searchVal ? (
        <>
          <div className={s.list}>
            <div className={s.list_title}>Популярные</div>
            {topCars.map((item) => (
              <div
                className={s.item}
                onClick={() => setSelectedCarId(item.id)}
                key={`top_item_${item.id}`}
              >
                {item.name}
              </div>
            ))}
          </div>
          <div className={cn(s.list, s.second_list)}>
            <div className={s.list_title}>Все</div>
            {allCars.map((item) => (
              <div
                className={s.item}
                onClick={() => setSelectedCarId(item.id)}
                key={`all_item_${item.id}`}
              >
                {item.name}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className={s.list}>
          <div className={s.list_title}>Результаты поиска</div>
          {filteredCars.map((item) => (
            <div
              className={s.item}
              onClick={() => setSelectedCarId(item.id)}
              key={`search_item_${item.id}`}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default React.memo(SelectCarStage)
