import { AuthContext } from 'application/app/AuthContext'
import { User } from 'application/domain/entity/user/User'
import { InputLabel } from 'application/presentation/common/InputComponents'
import { Button, Checkbox, ErrorMsg } from 'application/presentation/common/uiComponents'
import { getDomain } from 'application/utils/urlUtils'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import s from '../AuthPage.module.scss'

const RegForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const authContext = useContext(AuthContext)
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  //   const [phone, setPhone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [policyChecked, setPolicyChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    setName(urlParams.get('phone') || '')
    // setPhone(urlParams.get('phone') || '')
    setEmail(urlParams.get('email') || '')
    setPassword(urlParams.get('password') || '')
    setConfirmPassword(urlParams.get('password') || '')
  }, [location])

  const registration = useCallback(
    () =>
      authContext.registration({
        data: {
          name,
          email,
          password,
          password_confirmation: confirmPassword,
          //   phone,
        },
        onStart: () => setIsLoading(true),
        onSuccess: (response: { token: string; user: User }) => {
          const domain = getDomain(window.location.origin, true)
          if (domain === 'isdon.ru') {
            window.location.href = `https://${response.user.owner_url}.${domain}/waiting?user_token=${response.token}&login=${email}&password=${password}`
          } else {
            navigate('/waiting', { replace: true })
          }
          setIsLoading(false)
        },
        onError: (msg: string) => {
          setIsLoading(false)
          setError(msg)
          console.log(msg)
        },
      }),
    [authContext, confirmPassword, email, name, navigate, password],
  )

  return (
    <div className={s.form}>
      <div className={s.title}>Зарегистрироваться</div>
      <div className={s.fields}>
        <InputLabel title={'Ваше имя'} value={name} onChange={setName} />
        <InputLabel title={'Электронная почта'} value={email} onChange={setEmail} />
        {/* <InputLabel title={'Номер телефона'} value={phone} onChange={setPhone} /> */}
        <InputLabel title={'Пароль'} value={password} onChange={setPassword} type="password" />
        <InputLabel
          title={'Повторите пароль'}
          value={confirmPassword}
          onChange={setConfirmPassword}
          type="password"
        />
        <Checkbox
          value={policyChecked}
          onChange={setPolicyChecked}
          title={
            'Я принимаю условия обработки персональных данных и условия лицензионного соглашения'
          }
          containerClassName={s.checkbox_container}
          className={s.checkbox}
          activeClassName={s.active_checkbox}
          textClassName={s.checkbox_txt}
        />
        {error ? <ErrorMsg text={error} /> : <></>}
        <Button
          text="Поехали!"
          containerClassName={s.big_btn}
          color={!policyChecked ? 'gray' : 'orange'}
          disabled={!policyChecked || isLoading}
          onClick={registration}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default React.memo(RegForm)
