export const getDomain = (url: string, subdomain: boolean) => {
  subdomain = subdomain || false

  let urlStr: any = url.replace(/(https?:\/\/)?(www.)?/i, '')

  if (!subdomain) {
    urlStr = urlStr.split('.')

    urlStr = urlStr.slice(urlStr.length - 2).join('.')
  }

  if (urlStr.indexOf('/') !== -1) {
    return urlStr.split('/')[0]
  }

  return urlStr
}
