import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { recalculateBasketDiscont } from 'application/domain/entity/basket/basketActions'
import { getRandomInRange } from 'application/utils/getRandomInRange'
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { map, filter, find, findIndex } from 'lodash'
import moment from 'moment'
import { ProductType } from 'types/catalogType'

export type Basket = Array<ProductType>

type BasketState = {
  basket: Basket
  editProduct: ProductType | null
}

const initialState: BasketState = {
  basket: [],
  editProduct: null,
}

export const basketReducer = createSlice({
  name: 'basketReducer',
  initialState,
  reducers: {
    addProductAction: (state, { payload: product }: PayloadAction<ProductType>) => {
      state.basket.push({ ...product, uniq_id: `${moment().unix()}${getRandomInRange(1, 1000)}` })
      state.basket = recalculateBasketDiscont(state.basket)
      localStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    removeProductAction: (state, { payload: product }: PayloadAction<ProductType>) => {
      state.basket = filter(state.basket, (p) => p.uniq_id !== product.uniq_id)
      state.basket = recalculateBasketDiscont(state.basket)
      localStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    removeProductsAction: (state, { payload: uniq_ids }: PayloadAction<Array<string>>) => {
      state.basket = filter(
        state.basket,
        (p: ProductType) => findIndex(uniq_ids, (i: string) => p.uniq_id === i) === -1,
      )
      state.basket = recalculateBasketDiscont(state.basket)
      localStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    editProductAction: (state, { payload: product }: PayloadAction<ProductType>) => {
      state.basket = map(state.basket, (item) => {
        const varietyVol = find(product.varieties, { selected: true })
        if (product.uniq_ids && product.uniq_ids.length) {
          if (product.uniq_ids.indexOf(item.uniq_id) !== -1) {
            return {
              ...item,
              discont: product.discont,
              options: product.options,
              temperature: product.temperature,
              varieties: product.varieties,
              variety: { ...product.variety, name: varietyVol ? varietyVol.name : '' },
            }
          }
        } else {
          if (product.uniq_id === item.uniq_id) {
            return {
              ...item,
              discont: product.discont,
              options: product.options,
              temperature: product.temperature,
              varieties: product.varieties,
              variety: { ...product.variety, name: varietyVol ? varietyVol.name : '' },
            }
          }
        }

        return item
      })
      state.basket = recalculateBasketDiscont(state.basket)
      localStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    setBasketDataAction: (state, { payload: basket }: PayloadAction<Basket>) => {
      state.basket = map(basket, (item) => ({
        ...item,
        uniq_id: `${moment().unix()}${getRandomInRange(1, 1000)}`,
      }))
      state.basket = recalculateBasketDiscont(state.basket)
      localStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    setEditProductDataAction: (state, { payload: product }: PayloadAction<ProductType>) => {
      state.editProduct = product
    },
  },
})

const {
  addProductAction,
  removeProductAction,
  setBasketDataAction,
  removeProductsAction,
  editProductAction,
  setEditProductDataAction,
} = basketReducer.actions

export const basketIsNotEmpty = (basket: Basket) => (basket.length ? true : false)

export const addProduct = (product: ProductType) => (dispatch: Dispatch) =>
  dispatch(addProductAction(product))

export const editProduct = (product: ProductType) => (dispatch: Dispatch) =>
  dispatch(editProductAction(product))

export const removeProduct = (product: ProductType) => (dispatch: Dispatch) =>
  dispatch(removeProductAction(product))

export const removeProducts = (uniq_ids: Array<string>) => (dispatch: Dispatch) =>
  dispatch(removeProductsAction(uniq_ids))

export const setBasket = (basket: Basket) => (dispatch: Dispatch) =>
  dispatch(setBasketDataAction(basket))

export const setEditProductData = (product: ProductType) => (dispatch: Dispatch) =>
  dispatch(setEditProductDataAction(product))

export default basketReducer.reducer
