import { useTheme } from 'application/presentation/common/Hooks/useTheme'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import s from './ProductItem.module.scss'

type ProductItemProps = {
  color: 'green' | 'orange' | 'material'
  nonActiveColor?: 'material'
  title: string | number
  price?: number
  className?: string
  id: number
  active: number
  setActive: Function
  onChange?: Function
  isOption?: boolean
  selectAdditional?: Function
  additionals?: any
  itemId?: number
  count?: number
  availability_status?: string
}

const ProductItem = ({
  color,
  title,
  price,
  className,
  id,
  active,
  setActive,
  onChange,
  nonActiveColor,
  isOption,
  selectAdditional,
  additionals,
  itemId,
  count,
  availability_status,
}: ProductItemProps) => {
  const isActive = useMemo(() => active === id, [active, id])
  const [counter, setCounter] = useState(1)
  const { isLightTheme } = useTheme()

  useEffect(() => {
    if (isOption && isActive && count) {
      setCounter(count)
    }
    return () => {
      setCounter(1)
    }
  }, [count, isActive, isOption])

  const incrementCountHandler = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setCounter((v) => v + 1)
  }, [])

  const decrementCountHandler = useCallback((e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setCounter((v) => Math.max(v - 1, 1))
  }, [])

  const changeCounterHandler = useCallback(() => {
    if (selectAdditional && itemId && additionals && id) {
      selectAdditional(_.find(additionals, { id: itemId }), id, counter)
    }
  }, [additionals, counter, id, itemId, selectAdditional])

  const isAvailable = useMemo(() => availability_status === 'in_stock', [availability_status])

  useEffect(() => {
    if (isOption && isActive && price) {
      changeCounterHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, isOption, isActive, price])

  return (
    <div
      onClick={() => {
        if (onChange) {
          onChange(id)
        }
        setActive(id)
      }}
      className={`${s.item} ${price ? s.item_center : ''} ${
        nonActiveColor ? `${s[nonActiveColor]} ${isLightTheme ? s.light_material : ''}` : ''
      } ${isActive ? `${s[`active_${color}`]} ${isLightTheme ? s.light_active : ''}` : ''} ${
        className || ''
      } ${isOption && isActive && price ? s.item_option : ''} ${isLightTheme ? s.light : ''}`}
    >
      {isOption && isActive && price ? (
        <div className={s.float_handles}>
          <div className={s.plus} onClick={(e: any) => incrementCountHandler(e)}>
            <div className={s.icon}></div>
          </div>
          <div className={s.minus} onClick={(e: any) => decrementCountHandler(e)}>
            <div className={s.icon}></div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={`${s.title} ${!price ? s.title_center : ''}`}>
        {title} {isActive && price && isOption && counter !== 0 ? `(${counter}шт) ` : ''}
      </div>
      {price && price !== 0 ? <div className={s.price}>{price} руб</div> : <></>}
      {isAvailable && <div className={s.in_stock_badge}></div>}
    </div>
  )
}

export default React.memo(ProductItem)
