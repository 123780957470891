import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { isBarista } from 'application/presentation/UserController'
import cn from 'classnames'
import { AuthContext } from 'application/app/AuthContext'
import { IsPortraitOrientationContext } from 'application/app/isPortraitOrientation'
import { RootState } from 'application/domain/store/store'
import { useTranslation } from 'react-i18next'

import s from '../Header.module.scss'
import { Button } from '../../uiComponents'

const BaristaHeader = () => {
  const { t } = useTranslation()
  const isPortrait = useContext(IsPortraitOrientationContext)
  const authContext = useContext(AuthContext)
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const userIsBarista = useMemo(() => isBarista(user), [user])
  const basicBarista = user?.session?.state === 'active'

  return (
    <div className={s.nav}>
      <NavLink
        to="/"
        className={({ isActive }) => cn(s.nav_item, isActive ? s.active : null)}
        end
        dangerouslySetInnerHTML={{ __html: t('header.menu.barista.control_panel') || '' }}
      ></NavLink>
      {userIsBarista && basicBarista ? (
        <>
          <NavLink
            to="/barista_panel"
            className={({ isActive }) => cn(s.nav_item, isActive ? s.active : null)}
            dangerouslySetInnerHTML={{ __html: t('header.menu.barista.barista_panel') || '' }}
          ></NavLink>
          <NavLink
            to="/kassa_panel"
            className={({ isActive }) => cn(s.nav_item, isActive ? s.active : null)}
            dangerouslySetInnerHTML={{ __html: t('header.menu.barista.kassa_panel') || '' }}
          ></NavLink>
          <NavLink
            to="#timeout"
            className={s.nav_item}
            end
            dangerouslySetInnerHTML={{ __html: t('header.menu.barista.timeout') || '' }}
          ></NavLink>
        </>
      ) : userIsBarista && !basicBarista ? (
        <>
          <NavLink
            to="/barista_panel"
            className={({ isActive }) => cn(s.nav_item, isActive ? s.active : null)}
            dangerouslySetInnerHTML={{ __html: t('header.menu.barista.barista_panel') || '' }}
          ></NavLink>
        </>
      ) : (
        <></>
      )}
      {isPortrait && <Button text="Выйти" color="red" onClick={() => authContext.logout()} />}
    </div>
  )
}

export default React.memo(BaristaHeader)
