import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useQueryErrorResetBoundary } from 'react-query'
import Bugsnag from '@bugsnag/js'

import { Button } from './common/uiComponents'
import s from './ErrorBoundary.module.scss'

const errorHandler = (error: Error) => {
  Bugsnag.notify(error)
}

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: any
  resetErrorBoundary: () => void
}) {
  return (
    <div className={s.errorContainer}>
      <div className={s.errorContent}>
        <div className={s.errorText}>Что то пошло не так</div>
        <div className={s.errorDetailText}>{error ? error.toString() : null}</div>
        <Button text="Попробовать снова" onClick={() => resetErrorBoundary()} color="red" />
      </div>
    </div>
  )
}

export const ErrorHandler = ({ children }: { children: React.ReactNode }) => {
  const { reset } = useQueryErrorResetBoundary()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler} onReset={reset}>
      {children}
    </ErrorBoundary>
  )
}
