import React, { useContext, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { IsPortraitOrientationContext } from 'application/app/isPortraitOrientation'
import { RootState } from 'application/domain/store/store'
import { IsMobileContext } from 'application/app/IsMobile'

import NavigationMenu from '../NavigationMenu'
import PortraitHeaderMenu from '../Header/PortraitHeaderMenu'

import s from './PageLayout.module.scss'

type PageLayoutContextType = {
  showMenu: (show: boolean) => void
} | null

export const PageLayoutContext = React.createContext<PageLayoutContextType>(null)

export const PageLayoutProvider = ({ children }: any) => {
  const [menuIsVisible, setMenuIsVisible] = useState<boolean>(true)
  const isMobile = useContext(IsMobileContext)
  const isPortrait = useContext(IsPortraitOrientationContext)
  const ref = React.useRef<any>()
  const { pathname } = useLocation()

  const { showNavMenu, showHeaderMenu, collapseNavMenu } = useSelector(
    ({ navReducer }: RootState) => ({
      showNavMenu: navReducer.showNavMenu,
      showHeaderMenu: navReducer.showHeaderMenu,
      collapseNavMenu: navReducer.collapseNavMenu,
    }),
  )

  const pageLayoutFunc = useMemo(
    () => ({
      showMenu: (show: boolean) => {
        setMenuIsVisible(show)
      },
    }),
    [],
  )

  React.useEffect(() => {
    function updateScrollPosition() {
      const scrollTop = ref.current?.scrollTop
      if (scrollTop && scrollTop !== 110) {
        sessionStorage.setItem(pathname, `${scrollTop}`)
      }
    }

    const isPath = pathname.split('/').length - 1 === 3

    if (ref && ref.current && pathname.indexOf('control/menu') !== -1 && isPath) {
      ref.current.addEventListener('scroll', updateScrollPosition)
      return function cleanup() {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.removeEventListener('scroll', updateScrollPosition)
      }
    }
  }, [pathname])

  React.useEffect(() => {
    const scrolledY = sessionStorage.getItem(pathname) ?? 0
    if (ref && ref.current) {
      ref.current.scrollTo(0, Number(scrolledY))
    }
  }, [pathname])

  return (
    <PageLayoutContext.Provider value={pageLayoutFunc}>
      <div className={s.page_layout}>
        {menuIsVisible && (
          <>
            <div
              className={cn(
                s.menu,
                isPortrait && showNavMenu ? s.showed : '',
                !isMobile && collapseNavMenu ? s.collapse : '',
              )}
            >
              <NavigationMenu />
            </div>
            {isPortrait && (
              <div className={cn(s.menu, s.header_menu, showHeaderMenu ? s.showed : '')}>
                <PortraitHeaderMenu />
              </div>
            )}
          </>
        )}

        <div id="page_content" ref={ref} className={cn(s.content)}>
          {children}
        </div>
      </div>
    </PageLayoutContext.Provider>
  )
}

export const { Consumer } = PageLayoutContext
