import { HeaderFooterContext } from 'application/app/HeaderFooter'
import { PageLayoutContext } from 'application/presentation/common/PageLayout/PageLayoutContext'
import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react'
import Webcam from 'react-webcam'
import { ReactComponent as PhotoSvg } from 'images/icons/photo_icon_black.svg'
import SimpleMobileHeader from 'application/presentation/common/Header/SimpleMobileHeader'
import apiClient from 'application/data/apiClient/apiClient'
import Loader from 'application/presentation/common/Loader/Loader'
import InputMask from 'react-input-mask'
import { searchClientConfigs } from 'application/domain/useCase/clientConfigs/getClientConfigs'
import { Button } from 'application/presentation/common/uiComponents'
import _find from 'lodash/find'
import { useLocation, useNavigate } from 'react-router'
import AddOrEditCarMW from 'application/presentation/common/ModalWindows/AddOrEditCarMW/AddOrEditCarMW'

import s from './ScanCarPage.module.scss'
import ClientItem from './components/ClientItem'
import EmptyClientItem from './components/EmptyClientItem'

const sendPhoto = async (sendData: any) => {
  const { data } = await apiClient.post('/api/barista/autoNumber', sendData)
  return data
}

function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(','),
    //@ts-ignore
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

const ScanCarPage = () => {
  const navigate = useNavigate()
  const { search: urlSearch } = useLocation()
  const headerContext = useContext(HeaderFooterContext)
  const pageContext = useContext(PageLayoutContext)
  const [uploadIsLoading, setUploadIsLoading] = useState<boolean>(false)
  const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false)
  const [clientsData, setClientsData] = useState<Array<any>>([])
  const [carNumber, setCarNumber] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<number>(0)

  const webcamRef = useRef<any>(null)
  const [imgSrc, setImgSrc] = useState<any>(null)

  const tableData = useMemo(() => {
    const p = new URLSearchParams(urlSearch)
    return {
      tableId: parseInt(p.get('table_id') || '0', 10),
      tableNum: p.get('table_num'),
    }
  }, [urlSearch])

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImgSrc(imageSrc)
    setUploadIsLoading(true)
    const file = dataURLtoFile(imageSrc, 'photo.jpeg')
    // console.log()
    const fd = new FormData()
    fd.append('image', file)
    sendPhoto(fd)
      .then((responseData) => {
        const number = responseData.body.car_number_labels[0].labels[0].rus
        setCarNumber(number || '')
        setUploadIsLoading(false)
      })
      .catch(() => {
        setUploadIsLoading(false)
      })
  }, [webcamRef, setImgSrc])

  const clearImage = useCallback(() => setImgSrc(''), [])

  const search = useCallback((num: string) => {
    const carnum = num.slice(0, 6)
    setSearchIsLoading(true)
    searchClientConfigs({
      number: carnum,
    })
      .then((responseData) => {
        setClientsData(responseData)
        setSearchIsLoading(false)
      })
      .catch(() => {
        setSearchIsLoading(false)
      })
  }, [])

  const onClientItemClickHandler = useCallback((item: any) => {
    setSelectedItem(item.id)
  }, [])

  const onBtnClickHandler = useCallback(
    (withSelected?: boolean) => {
      if (withSelected && selectedItem) {
        const f = _find(clientsData, (item) => item.id === selectedItem)
        navigate(
          `/kassa_panel?table_id=${tableData.tableId}&table_num=${tableData.tableNum}&client_config_id=${selectedItem}&client_phone=${f.client.phone}&is_from_orders=true#add_time`,
        )
      } else {
        navigate(
          `/scan_car?table_id=${tableData.tableId}&table_num=${tableData.tableNum}&fullCarNumber=${carNumber}&is_from_orders=true#add_car`,
        )
      }
    },
    [selectedItem, clientsData, navigate, carNumber, tableData],
  )

  const onAddCarHandler = useCallback(() => {
    navigate(
      `/kassa_panel?table_id=${tableData.tableId}&table_num=${tableData.tableNum}&car_from_ls=true&is_from_orders=true#add_time`,
      { replace: true },
    )
  }, [navigate, tableData])

  useEffect(() => {
    headerContext?.showHeader(false)
    pageContext?.showMenu(false)
    return () => {
      headerContext?.showHeader(true)
      pageContext?.showMenu(true)
    }
  }, [headerContext, pageContext])

  useEffect(() => {
    const num = carNumber.replace(/\s/g, '')
    if (num.length >= 6) {
      search(num)
    } else {
      setClientsData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carNumber])

  return (
    <>
      {imgSrc ? (
        <div className={s.scan_container}>
          <SimpleMobileHeader title="СКАНИРОВАНИЕ АВТО" onBackClick={clearImage} />
          <div className={s.preview_container}>
            <img src={imgSrc} className={s.car_img} />
          </div>
          <div className={s.number_container}>
            <div className={s.number}>
              {uploadIsLoading ? (
                <Loader size={16} />
              ) : (
                <InputMask
                  value={carNumber}
                  onChange={(e: any) => setCarNumber(e.target.value.toUpperCase())}
                  className={s.input}
                  formatChars={{
                    '9': '[0-9]',
                    r: '[А-Яа-я]',
                    a: '[A-Za-zА-Яа-я]',
                    '*': '[A-Za-zА-Яа-я0-9]',
                  }}
                  maskChar={''}
                  mask={'r 999 rr 999'}
                  placeholder="A 000 AA 000"
                />
              )}
            </div>
          </div>
          {carNumber.replace(/\s/g, '').length >= 6 ? (
            <>
              <div className={s.clients_container}>
                {searchIsLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div className={s.subtitle}>Выберите авто и клиента</div>
                    {clientsData.map((item) => (
                      <ClientItem
                        item={item}
                        onItemClick={onClientItemClickHandler}
                        isActive={item.id === selectedItem}
                      />
                    ))}
                    <EmptyClientItem
                      listIsEmpty={clientsData.length === 0}
                      onClick={() => onBtnClickHandler(false)}
                    />
                  </>
                )}
              </div>
              {clientsData.length ? (
                selectedItem ? (
                  <Button
                    color="fiol"
                    text="Выбрать"
                    onClick={() => onBtnClickHandler(true)}
                    containerClassName={s.btn_container}
                  />
                ) : (
                  <></>
                )
              ) : (
                <Button
                  color="fiol"
                  text="Добавить авто"
                  onClick={() => onBtnClickHandler(false)}
                  containerClassName={s.btn_container}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <>
          <div className={s.container}>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className={s.video_container}
              videoConstraints={{
                facingMode: 'environment',
              }}
            />
            <div className={s.photo_btn_container}>
              <button onClick={capture} className={s.photoBtn}>
                <PhotoSvg />
              </button>
            </div>
          </div>
        </>
      )}

      <AddOrEditCarMW clientId={0} onAddCar={onAddCarHandler} />
    </>
  )
}

export default React.memo(ScanCarPage)
