import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import NavSvg from 'images/icons/nav_stat.svg'
import { ReactSVG } from 'react-svg'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/store'

import s from '../NavigationMenu.module.scss'

export const conditerRoutes = [
  {
    name: 'Заявки',
    link: '/',
    t_name: 'bids',
    icon: NavSvg,
  },
  {
    name: 'Содержимое склада',
    link: '/stock',
    t_name: 'contain_stock',
    icon: NavSvg,
  },
  {
    name: 'Композитные расходники',
    link: '/atoms',
    t_name: 'atoms',
    icon: NavSvg,
  },
  {
    name: 'Производство',
    link: '/production',
    t_name: 'production',
    icon: NavSvg,
  },
]

const ConditerMenu = () => {
  const { t } = useTranslation()
  const { collapseNavMenu } = useSelector(({ navReducer }: RootState) => ({
    collapseNavMenu: navReducer.collapseNavMenu,
  }))
  return (
    <div className={s.nav}>
      {conditerRoutes.map((item) => (
        <NavLink
          to={item.link}
          className={({ isActive }) => cn(s.nav_item, isActive ? s.active : null)}
          end
        >
          <ReactSVG src={item.icon} className={s.item_icon} />
          <div className={`${s.item_text} ${collapseNavMenu ? s.collapse_text : ''}`}>
            {t(`nav.menu.${item.t_name}.title`)}
          </div>
        </NavLink>
      ))}
    </div>
  )
}

export default React.memo(ConditerMenu)
