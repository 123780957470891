/* eslint-disable @typescript-eslint/naming-convention */
import _find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'
import filter from 'lodash/filter'
import concat from 'lodash/concat'
import { ProductType } from 'types/catalogType'
import { Product, ProductOption, ProductOptionItem } from 'types/productType'

export const recalculateBasketDiscont = (basketData: ProductType[]) => {
  const donutsVarityIds = [728, 1891, 1794]
  const getDonutDiscont = (item: any, donutsCount: number) => {
    if (donutsCount > 5) {
      return 16.666
    }
    if (donutsCount > 2 && donutsCount < 6) {
      return 8.333
    }
    return item.discont ? item.discont : 0
  }

  let c = 0
  if (basketData && basketData.length) {
    basketData.forEach((item: any) => {
      const volume = _find(item.varieties, { selected: true })
      if (volume && donutsVarityIds.indexOf(volume.variety_id) !== -1) {
        c = c + 1
      }
    })
    return basketData.map((item: any) => {
      const volume = _find(item.varieties, { selected: true })
      if (volume && donutsVarityIds.indexOf(volume.variety_id) !== -1) {
        return {
          ...item,
          discont: getDonutDiscont(item, c),
        }
      }
      return item
    })
  }
  return []
}

export const mergeBasketData = (currentBasket: any, calculatedBasket: any) => {
  if (calculatedBasket && currentBasket) {
    const temp = cloneDeep(currentBasket)

    return temp.map((item: Product) => {
      const f = _find(calculatedBasket, (calc_item) => calc_item.uniq_id === item.uniq_id)
      if (f) {
        item.full_cost = f.full_cost
        item.product_name = f.product_name
        if (f.temperature) {
          item.temperature = f.temperature
        }
        item.local_discont = item.discont
        if (f.discont) {
          item.discont = f.discont
        }
        if (item.variety && item.variety.cost) {
          item.variety.cost = f.variety.cost
        } else {
          item.variety = f.variety
        }
        if (f.options) {
          item.options.map((o: ProductOption) => {
            o.items.map((v) => {
              f.options.map((new_o: ProductOptionItem) => {
                if (new_o.id == v.variety_id) {
                  v.cost = new_o.cost
                  v.availability_status = new_o.availability_status
                }
              })
              return v
            })
            return item
          })
        }
      }
      return item
    })
  }
  return currentBasket
}

const isEqual = (a: ProductType, b: ProductType) => {
  return (
    _isEqual(a.product_name, b.product_name) &&
    _isEqual(a.varieties, b.varieties) &&
    _isEqual(a.options, b.options)
  )
}

export const groupBasketData = (currentBasket: any) => {
  const withDiscont = filter(currentBasket, (i) => i.discont && i.discont > 0)
  const withoutDiscont = filter(currentBasket, (i) => !i.discont || i.discont === 0)
  const uniq = uniqWith(withoutDiscont, (a: ProductType, b: ProductType) => {
    return isEqual(a, b)
  })
  const uniq_items = uniq.map((item) => {
    let c = 0
    let sum = 0
    let uniq_ids: Array<string> = []
    withoutDiscont.map((basketItem: ProductType) => {
      if (isEqual(item, basketItem)) {
        c = c + 1
        sum = sum + basketItem.full_cost
        uniq_ids.push(basketItem.uniq_id)
      }
    })
    return { ...item, count: c, uniq_ids, full_cost: sum }
  })
  return withDiscont && withDiscont.length ? concat(withDiscont, uniq_items) : uniq_items
}
