/* eslint-disable @typescript-eslint/naming-convention */

import _ from 'lodash'
import React, { useMemo } from 'react'
import { OrderType } from 'types/orderType'

import { useTheme } from '../../Hooks/useTheme'

import s from './OrderMW.module.scss'

type Props = {
  data: OrderType
  i: number
}

const OrderItem = ({ data, i }: Props) => {
  const { isLightTheme } = useTheme()
  const selectedVolume = useMemo(() => {
    return data && data.varieties ? data.varieties[0] : null
  }, [data])
  const selectedAdditionals = useMemo(() => {
    if (data) {
      const selected = _.drop(data.varieties)
      return selected
    }
    return null
  }, [data])

  const full_cost = useMemo(() => {
    let cost = 0
    if (data && data.varieties) {
      data.varieties.map((item) => {
        cost = cost + item.variety_item_cost
      })
    }
    return cost
  }, [data])

  return (
    <div className={`${s.item_container} ${isLightTheme ? s.light : ''}`}>
      {data.discont ? <div className={s.discont}>Скидка {data.discont}%</div> : <></>}
      <div className={s.item_inner_container}>
        <div className={s.orange}>{i + 1}</div>
        <div className={s.info}>
          <div className={s.name}>
            {data.p_name}{' '}
            {data.temperature ? `(${data.temperature === 'warm' ? 'Теплый' : 'Горячий'})` : ''}
          </div>
          {selectedVolume ? (
            <div className={s.value}>
              {selectedVolume.v_name} ({selectedVolume.variety_item_cost} ₽)
            </div>
          ) : (
            <></>
          )}
          {selectedAdditionals && selectedAdditionals.length ? (
            <div className={s.adds}>
              {selectedAdditionals.map((item) => (
                <div className={s.add_item} key={item.v_name}>
                  + {item.v_name} ({item.variety_item_cost} ₽)
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={s.orange}>{full_cost} ₽</div>
      </div>
    </div>
  )
}

export default React.memo(OrderItem)
