/* eslint-disable @typescript-eslint/naming-convention */
import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import { RootState } from 'application/domain/store/store'
import { checkTechTimeOutlet, editTechTimeOutlet } from 'application/domain/useCase/outlet/outlet'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import TimeField from 'react-simple-timefield'

import Loader from '../../Loader'
import { Button } from '../../uiComponents'
import MWContainer from '../MWContainer'

import s from './TechnicalTimeoutMW.module.scss'

const TechnicalTimeoutMW = () => {
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const { hash } = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const opened = useMemo(() => {
    return hash.indexOf('timeout') !== -1
  }, [hash])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [editIsLoading, setEditIsLoading] = useState<boolean>(false)
  const [outletIsWorking, setOutletIsWorking] = useState<boolean>(false)
  const [timeStart, setTimeStart] = useState<string>('10:00')
  const [timeEnd, setTimeEnd] = useState<string>('10:15')

  const checkTechTimeHandler = useCallback((outlet_id: number) => {
    return checkTechTimeOutlet({
      outlet_id,
      onSuccess: (res: any) => {
        setOutletIsWorking(res.is_working)
        queryClient.invalidateQueries('techTime')
        setIsLoading(false)
      },
      onError: (err: any) => {
        setIsLoading(false)
      },
    })
  }, [])

  useEffect(() => {
    if (opened) {
      const now = moment()
      setTimeStart(now.format('HH:mm'))
      setTimeEnd(now.add('minutes', 15).format('HH:mm'))
    }
    if (opened && user && user.session.outlet_id) {
      setIsLoading(true)
      checkTechTimeHandler(user.session.outlet_id)
    }
  }, [user, opened, dispatch, checkTechTimeHandler])

  const action = useCallback(() => {
    if (!editIsLoading && user && user.session.outlet_id) {
      setEditIsLoading(true)
      const { outlet_id } = user.session
      return editTechTimeOutlet({
        outlet_id,
        outletIsWorking,
        timeStart,
        timeEnd,
        onSuccess: (res: any) => {
          setOutletIsWorking(outletIsWorking ? false : true)
          setEditIsLoading(false)
          queryClient.invalidateQueries('techTime')
        },
        onError: (err: any) => {
          setEditIsLoading(false)
        },
      })
    }
  }, [editIsLoading, user, outletIsWorking, timeStart, timeEnd])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (hash.indexOf('bonus_card') !== -1) {
          navigate(-1)
        }
      }}
    >
      <div className={s.title}>Технический перерыв</div>
      <div className={s.inner_container}>
        {isLoading ? (
          <Loader color="#fff" />
        ) : (
          <>
            <div className={s.times}>
              <TimeField value={timeStart} onChange={(e) => setTimeStart(e.target.value)} />
              <span> - </span>
              <TimeField value={timeEnd} onChange={(e) => setTimeEnd(e.target.value)} />
            </div>
            <Button
              color={editIsLoading ? 'orange' : outletIsWorking ? 'green' : 'red'}
              text={outletIsWorking ? 'Включить' : 'Отключить'}
              onClick={() => action()}
              containerClassName={s.btn}
              isLoading={editIsLoading}
            />
          </>
        )}
      </div>
    </MWContainer>
  )
}

export default React.memo(TechnicalTimeoutMW)
