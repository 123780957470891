import React from 'react'
import {
  isAdept,
  isAdmin,
  isBarista,
  isConditer,
  isSupplier,
  isUserHaveOnlyExtrasession,
  isUserHaveRole,
} from 'application/presentation/UserController'
import { Navigate } from 'react-router'
import loadable from '@loadable/component'
import { useAppSelector } from 'application/domain/store/hooks'

import ExtraSessionNavigation from './ExtraSessionNavigation'

const PanelNavigation = loadable(() => import('./PanelNavigation'))
const SupplierNavigation = loadable(() => import('./SupplierNavigation'))
const AdminNavigation = loadable(() => import('./AdminNavigation'))
const ConditerNavigation = loadable(() => import('./ConditerNavigation'))

const MainNavigation = () => {
  const user = useAppSelector(({ userReducer }) => userReducer.user)
  const appMode = useAppSelector(({ userReducer }) => userReducer.appMode)

  if (isUserHaveRole(user)) {
    if (
      appMode === 'booking' &&
      isUserHaveOnlyExtrasession(user) &&
      (isBarista(user) || isAdept(user))
    ) {
      return <ExtraSessionNavigation />
    }
    if (isAdmin(user)) {
      return <AdminNavigation />
    }
    if (isBarista(user) || isAdept(user)) {
      return <PanelNavigation />
    }
    if (isConditer(user)) {
      return <ConditerNavigation />
    }
    if (isSupplier(user)) {
      return <SupplierNavigation />
    }
  }
  return <Navigate to="/login" replace />
}

export default React.memo(MainNavigation)
