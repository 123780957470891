import React from 'react'
import { Button } from 'application/presentation/common/uiComponents'

import s from '../AddCarPage.module.scss'

type Props = {
  onNextPressHandler: Function
  saveIsLoading: boolean
  fetchedCar: {
    name: string
    modelName: string
    number: string
  }
  onManualPressHandler: Function
}

const CarSubmitStage = ({
  onNextPressHandler,
  saveIsLoading,
  fetchedCar,
  onManualPressHandler,
}: Props) => {
  return (
    <div className={s.auto_container}>
      <div className={s.inner_container}>
        <div className={s.title}>
          Подскажите, <br />
          Этот автомобиль?
        </div>
        <div className={s.car_container}>
          <div className={s.top}>
            <div className={s.name}>
              {fetchedCar.name} {fetchedCar.modelName}
            </div>
            <div className={s.number}>
              <span>{fetchedCar.number}</span>
            </div>
          </div>
        </div>
        <Button
          text="Этот автомобиль"
          color="fiol"
          containerClassName={s.btn}
          onClick={onNextPressHandler}
          isLoading={saveIsLoading}
        />
        <Button
          text="Не этот"
          color="gray"
          containerClassName={s.btn}
          onClick={onManualPressHandler}
        />
      </div>
    </div>
  )
}

export default React.memo(CarSubmitStage)
