import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import s from '../Header.module.scss'

const SupplierHeader = () => {
  const { t } = useTranslation()
  return (
    <div className={s.nav}>
      <NavLink
        to="/"
        className={({ isActive }) => cn(s.nav_item, isActive ? s.active : null)}
        end
        dangerouslySetInnerHTML={{ __html: t('header.menu.supplier.report') || '' }}
      ></NavLink>
    </div>
  )
}

export default React.memo(SupplierHeader)
