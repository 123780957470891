/* eslint-disable @typescript-eslint/naming-convention */
import _ from 'lodash'
import moment from 'moment'
import { ProductType } from 'types/catalogType'
import { Product, ProductOption, ProductOptionItem } from 'types/productType'

type BasketActionType = {
  product: ProductType
  oldData: any
  dispatch: any
  type: 'add' | 'delete' | 'all_delete' | 'edit'
}

export const getRandomInRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

// TODO: заебать романыча и убрать это говно
export const recalculateBasketDiscont = (basketData: ProductType[]) => {
  let c = 0
  if (basketData && basketData.length) {
    basketData.forEach((item: any) => {
      const volume = _.find(item.varieties, { selected: true })
      if (
        (volume && volume.variety_id === 728) ||
        (volume && volume.variety_id === 1891) ||
        (volume && volume.variety_id === 1794)
      ) {
        c = c + 1
      }
    })
    return basketData.map((item: any) => {
      const volume = _.find(item.varieties, { selected: true })
      if (
        (volume && volume.variety_id === 728) ||
        (volume && volume.variety_id === 1891) ||
        (volume && volume.variety_id === 1794)
      ) {
        return {
          ...item,
          discont: c > 2 && c < 6 ? 8.33 : c > 5 ? 16.66 : item.discont ? item.discont : 0,
        }
      }
      return item
    })
  }
  return []
}

export const getProductPrice = (product: any) => {
  let tempPrice = 0
  if (product && product.varieties && product.varieties.length) {
    const min_val = _.sortBy(product.varieties, 'cost')
    tempPrice += min_val[0].cost
  }
  return tempPrice
}

export const getProductFullPrice = (product: any) => {
  let tempPrice = 0
  let opt = _.filter(product.options, { selected: true })
  if (opt.length) {
    opt.map((item: any) => {
      if (item.items) {
        item.items.map((item2: any) => {
          if (item2.selected) {
            tempPrice += item2.cost
          }
        })
      }
    })
  }
  if (product.varieties) {
    const sel: any = _.find(product.varieties, { selected: true })
    if (sel) {
      tempPrice += sel.cost
    } else {
      tempPrice += product.varieties[0].cost
    }
  }
  return tempPrice
}

export const getSelectedAdditionas = (product: any) => {
  let arr: any = []
  if (product) {
    let temp = _.filter(product.options, { selected: true })
    temp.map((item: any) => {
      item.items.map((item2: any) => {
        if (item2.selected) {
          arr.push(item2.name)
        }
      })
    })
  }
  return arr
}

export const getSelectedAdditionasIds = (product: any) => {
  let arr: any = []
  if (product) {
    let temp = _.filter(product.options, { selected: true })
    temp.map((item: any) => {
      item.items.map((item2: any) => {
        if (item2.selected) {
          if (item2.count > 1) {
            for (let i = 0; i < item2.count; i++) {
              arr.push(item2.variety_id)
            }
          } else {
            arr.push(item2.variety_id)
          }
        }
      })
    })
  }

  return Object.assign({}, arr)
}

const isEqual = (a: ProductType, b: ProductType) => {
  return (
    _.isEqual(a.product_name, b.product_name) &&
    _.isEqual(a.varieties, b.varieties) &&
    _.isEqual(a.options, b.options)
  )
}

export const groupBasketData = (currentBasket: any) => {
  const withDiscont = _.filter(currentBasket, (i) => i.discont && i.discont > 0)
  const withoutDiscont = _.filter(currentBasket, (i) => !i.discont || i.discont === 0)
  const uniq = _.uniqWith(withoutDiscont, (a: ProductType, b: ProductType) => {
    return isEqual(a, b)
  })
  const uniq_items = uniq.map((item) => {
    let c = 0
    let sum = 0
    let uniq_ids: Array<string> = []
    withoutDiscont.map((basketItem: ProductType) => {
      if (isEqual(item, basketItem)) {
        c = c + 1
        sum = sum + basketItem.full_cost
        uniq_ids.push(basketItem.uniq_id)
      }
    })
    return { ...item, count: c, uniq_ids, full_cost: sum }
  })
  return withDiscont && withDiscont.length ? _.concat(withDiscont, uniq_items) : uniq_items
}

export const getEditAdditionalsData = (product: ProductType) => {
  return product.options.map((item: any) => {
    if (item.selected) {
      const t = [true]
      item.items.forEach((opt: any) => {
        if (opt.selected) {
          t.push(opt.id)
        }
      })

      return { ...item, selected: t }
    }
    return { ...item, selected: [false] }
  })
}
