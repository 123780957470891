import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import NavSvg from 'images/icons/nav_stat.svg'
import { ReactSVG } from 'react-svg'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/store'

import s from '../NavigationMenu.module.scss'

export const supplierRoutes = [
  {
    name: 'Накладные',
    link: '/',
    t_name: 'invoices',
    icon: NavSvg,
  },
  {
    name: 'Содержимое склада',
    link: '/stocks',
    t_name: 'contain_stock',
    icon: NavSvg,
  },
  {
    name: 'Время привоза',
    link: '/deliveryTime',
    t_name: 'deliveryTime',
    icon: NavSvg,
  },
  {
    name: 'Пончики на точках',
    link: '/donutsOnOutlet',
    t_name: 'donutsOnOutlet',
    icon: NavSvg,
  },
  {
    name: 'Списание',
    link: '/writeOff',
    t_name: 'writeOff',
    icon: NavSvg,
  },
  {
    name: 'Контрагенты',
    link: '/contragents',
    t_name: 'contragents',
    icon: NavSvg,
  },
  {
    name: 'Обрабока заявок',
    link: '/processing',
    t_name: 'processing',
    icon: NavSvg,
  },
]

const SupplierMenu = () => {
  const { t } = useTranslation()
  const { collapseNavMenu } = useSelector(({ navReducer }: RootState) => ({
    collapseNavMenu: navReducer.collapseNavMenu,
  }))
  return (
    <div className={s.nav}>
      {supplierRoutes.map((item) => (
        <NavLink
          to={item.link}
          className={({ isActive }) => cn(s.nav_item, isActive ? s.active : null)}
          end
        >
          <ReactSVG src={item.icon} className={s.item_icon} />
          <div className={`${s.item_text} ${collapseNavMenu ? s.collapse_text : ''}`}>
            {t(`nav.menu.${item.t_name}.title`)}
          </div>
        </NavLink>
      ))}
    </div>
  )
}

export default React.memo(SupplierMenu)
